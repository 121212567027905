import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import Loader from 'react-content-loader';
import dayjs from 'dayjs';

import { handleError } from '../../utils/errors';
import { openModal } from '../../reducers/modals';
import { sendGTMEvent } from '../../reducers/company';
import {
  timezone as tzSelector,
  dateFormat as dateFormatSelector,
  country as countrySelector,
} from '../../selectors/company';
import {
  paymentsLoading,
  paymentsError,
  payments as paymentsSelector,
  baseAmount,
  startDate,
  isOpen,
} from '../../selectors/shifts';
import { get } from 'lodash';
import NewShiftsCurrent from './NewShiftsCurrent';
import { countriesFirstGroupNewDesign } from '../navigation/Header';

import { Icon } from '@alegradev/smile-ui-react';
import { useFormat } from '../../hooks/useFormat';

const textLoader = () => (
  <Loader title="" height={14} width={72}>
    <rect width='100%' height='100%' x="0" y="0" rx="5" ry="5" />
  </Loader>
)

const ShiftsCurrent = ({ onShowList, loadShiftPayments }) => {
  const dispatch = useDispatch();
  const shiftOpen = useSelector(isOpen)
  const { decimal, fmt } = useFormat();
  const loading = useSelector(paymentsLoading);
  const error = useSelector(paymentsError);
  const payments = useSelector(paymentsSelector);
  const base = useSelector(baseAmount);
  const timezone = useSelector(tzSelector);
  const start = useSelector(startDate);
  const dateFormat = useSelector(dateFormatSelector);
  const country = useSelector(countrySelector);

  if ((dayjs(start).isAfter('2023-04-20') && countriesFirstGroupNewDesign.includes(country)) ||
    (dayjs(start).isAfter('2023-05-30') && !countriesFirstGroupNewDesign.includes(country)))
    return <NewShiftsCurrent onShowList={onShowList} loadShiftPayments={loadShiftPayments} />

  const cash = new BigNumber(payments.cash);
  const debit = new BigNumber(payments.debit);
  const credit = new BigNumber(payments.credit);
  const transfer = new BigNumber(payments.transfer);
  const refunds = new BigNumber(payments.refunds);
  const inPayment = new BigNumber(payments.inPayment);
  const outPayment = new BigNumber(payments.outPayment);
  const totalsTipCash = new BigNumber(payments.tipsTotalCash);
  const totalsTipDebit = new BigNumber(payments.tipsTotalDebit);
  const totalsTipCredit = new BigNumber(payments.tipsTotalCredit);
  const totalsTipTransfer = new BigNumber(payments.tipsTotalTransfer);

  const totalSales = new BigNumber(0).plus(cash).plus(debit).plus(credit).plus(transfer);

  const totalsTipsOther = totalsTipDebit.plus(totalsTipCredit).plus(totalsTipTransfer);

  const total = new BigNumber(base)
    .plus(cash)
    .plus(debit)
    .plus(credit)
    .plus(transfer)
    .plus(inPayment)
    .plus(totalsTipCash)
    .plus(totalsTipsOther)
    .minus(refunds)
    .minus(outPayment)

  return (
    <div className="shifts__detail h-100 d-flex flex-column">

      <div className="shifts__detail-header mb-4 d-flex justify-content-between align-items-center">
        <h1 className="text-capitalize-first text-muted">
          {I18n.get('currentShift', 'turno en curso')}
        </h1>

        <div className="shifts__detail-header-actions d-flex justify-content-end align-items-center">
          {shiftOpen === true && (
            <button
              type="button"
              className="btn btn-submit text-nowrap"
              onClick={() => {
                try {
                  dispatch(openModal({ modal: 'closeShift', params: { attemptOrigin: "from shift history" } }))
                  dispatch(sendGTMEvent("shift-closing-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "success",
                  }))
                } catch {
                  dispatch(sendGTMEvent("shift-closing-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "failure",
                  }))
                }
              }}
            >
              {I18n.get('closeShift', 'cerrar turno')}
            </button>
          )}

          <button
            type="button"
            className="btn btn-submit d-block d-sm-none"
            onClick={() => onShowList()}
          >
            <Icon icon='list' extraClass="icon-white" />
          </button>
        </div>
      </div>

      {!!error && (
        <p className="text-capitalize-first text-danger h4">
          {I18n.get('thereIsAnErrorLoadingShiftValues', 'Hubo un error cargando los valores del turno.')}
          {' '}{handleError(error)}
        </p>
      )}

      {shiftOpen === true
        ? (
          <div className="shifts__detail-body card">

            <div className="d-flex form-row m-0 p-4 border-bottom">
              <div className="col-4 py-3">
                <div className="d-flex flex-column">
                  <strong className="text-capitalize-first h3">
                    {I18n.get('startDate', 'fecha de inicio')}
                  </strong>
                  <div className="h4">
                    {!!start ? dayjs.tz(start, timezone).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : null}
                  </div>
                </div>
              </div>
              <div className="col-4 py-3">
                <div className="d-flex flex-column">
                  <strong className="text-capitalize-first h3">
                    {I18n.get('base', 'base inicial')}
                  </strong>
                  <div className="h4">{new BigNumber(base).toFormat(decimal, fmt)}</div>
                </div>
              </div>
              <div className="col-4 py-3">
                <div className="d-flex flex-column">
                  <strong className="text-capitalize-first h3">
                    {I18n.get('sales', 'ventas')}
                  </strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4">{totalSales.toFormat(decimal, fmt)}</div>
                  }
                </div>
              </div>
            </div>

            <div className="p-4 d-flex flex-column">

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('base', 'base inicial')}</strong>
                <div className="h4">{new BigNumber(base).toFormat(decimal, fmt)}</div>
              </div>

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('cashSales', 'ventas en efectivo')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h4">{cash.toFormat(decimal, fmt)}</div>
                }
              </div>

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h4">{debit.toFormat(decimal, fmt)}</div>
                }
              </div>

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h4">{credit.toFormat(decimal, fmt)}</div>
                }
              </div>

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('transferSales', 'ventas por transferencias')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h4">{transfer.toFormat(decimal, fmt)}</div>
                }
              </div>

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('refundCash', 'Devolución de dinero')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h4 text-danger">{refunds.toFormat(decimal, fmt)}</div>
                }
              </div>

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('invoiceCash', 'ingresos de efectivo')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h4">{inPayment.toFormat(decimal, fmt)}</div>
                }
              </div>

              { (totalsTipCash.isGreaterThan(0) || totalsTipsOther.isGreaterThan(0)) &&
                <>
                  <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                    <strong className="text-capitalize-first h4">{I18n.get('shift.tipsCash', 'Propinas en efectivo')}</strong>
                    {!!loading
                      ? textLoader()
                      : <div className="h4">{totalsTipCash.toFormat(decimal, fmt)}</div>
                    }
                  </div>

                  <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                    <strong className="text-capitalize-first h4">{I18n.get('shift.tipsOther', 'Propinas en otros medios')}</strong>
                    {!!loading
                      ? textLoader()
                      : <div className="h4">{totalsTipsOther.toFormat(decimal, fmt)}</div>
                    }
                  </div>
                </>
              }

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('expenseCash', 'retiros de efectivo')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h4 text-danger">{outPayment.toFormat(decimal, fmt)}</div>
                }
              </div>

              {!!get(payments, 'currencies', null) && (
                Object.keys(payments.currencies).map((key, index) => (
                  <div key={index} className="d-flex align-items-center justify-content-between">
                    <strong className="text-capitalize-first h2">{`${I18n.get('total', 'total')} ${key}`}</strong>
                    {!!loading
                      ? textLoader()
                      : <div className="h3">
                        {new BigNumber(get(payments, `currencies.${key}.total`)).toFormat(decimal, {
                          prefix: get(payments, `currencies.${key}.symbol`),
                          decimalSeparator,
                          groupSeparator: decimalSeparator === "," ? "." : ",",
                          groupSize: 3,
                        })}
                      </div>
                    }
                  </div>
                ))
              )}

              <div className="d-flex align-items-center justify-content-between">
                <strong className="text-capitalize-first h2">{I18n.get('total', 'total')}</strong>
                {!!loading
                  ? textLoader()
                  : <div className="h3">{total.toFormat(decimal, fmt)}</div>
                }
              </div>
            </div>

          </div>

        )
        : (
          <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
            <p className="text-capitalize-first text-center">
              {I18n.get('actualShiftIsClosed', 'abre un nuevo turno para continuar haciendo operaciones')}
            </p>

            <button
              type="button"
              className="btn btn-submit text-nowrap"
              onClick={() => {
                try {
                  dispatch(openModal({ modal: 'openShift', params: { attemptOrigin: "from shift history" } }))
                  dispatch(sendGTMEvent("shift-opening-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "success",
                  }))
                } catch {
                  dispatch(sendGTMEvent("shift-opening-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "failure",
                  }))
                }
              }}
            >
              {I18n.get('openShift', 'abrir turno')}
            </button>
          </div>
        )
      }
    </div>
  )
}

export default ShiftsCurrent;