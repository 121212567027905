const dictionary = {
  "aboutPOS": "How to use Alegra POS?",
  "accept": "Accept",
  "accepted": "accepted",
  "acceptedWithObservations": "accepted with observations",
  "acceptTermsConditions": "I accept terms and conditions",
  "accountingAccount": "Accounting account",
  "accountingOptions": "Accounting setup",
  "accountingOrFinancialServices": "Accounting or financial services",
  "accountReceivable": "Receivable",
  "actions": "Actions",
  "activate": "Activate",
  "active": "Active",
  "activeFilter": "Active filters",
  "activeProducts": "Active",
  "actuallyYouHave": "You are currently in",
  "actualShiftIsClosed": "Open a new shift and continue managing your business cash",
  "actualStation": "Current station",
  "add": "Add",
  "addAnotherItem": "Add another product",
  "addCustomFieldHelp": "Select the additional fields you want to add",
  "addItem": "Add product",
  "additionalSettings": "Additional settings",
  "addOption": "Add option",
  "addPriceList": "Add price list",
  "address": "address",
  "addressTooltip": "You must include the address when you make the sale outside your premises or if you choose the municipality of your client",
  "addOptions": "Additional options",
  "addWarehouse": "Add warehouse",
  "adjusted": "Adjusted",
  "adjustedProducts": "Adjusted products",
  "adjustedTotal": "Adjustment total",
  "advancedOptions": "Advanced options",
  "advancedOptionsSubtitles": "You can configure the tax data of your client, payment term, seller and price list",
  "advertisingDigitalMedia": "Advertising / Digital Media",
  "agricultureLivestock": "Agriculture / Livestock",
  "Alegra": "Accounting",
  "AlegraAccounting": "Accounting",
  "AlegraAppsDescription": "Browse with one click between your Alegra products",
  "alegraAppsTitle": "Alegra apps",
  "alegraAccount": "Alegra account",
  "AlegraInvoicing": "Invoicing",
  "AlegraInvoicing.description.modern": "Electronic and traditional sales",
  "AlegraPayroll": "Payroll",
  "AlegraPayroll.description": "Settle and issue payments for your team.",
  "AlegraPayroll.description.modern": "Issue and settle payments for your team",
  "AlegraPlan": "Alegra plan",
  "AlegraPOS": "POS",
  "AlegraPOS.description": "Streamline your sales and control your cash",
  "AlegraPOS.description.modern": "Sell from your physical store",
  "AlegraShop": "Store",
  "AlegraShop.description": "Create your online store easily and quickly",
  "AlegraShop.description.modern": "Sell more with your business online",
  "alegraWeekIsHere1": "Happy Week is here with great offers!",
  "alegraWeekIsHere2": "Buy two months of your favorite Alegra POS plan and receive the",
  "alegraWeekIsHere3": "70% off",
  "all": "all",
  "allF": "all",
  "allInvoicesMadeFrom": "All invoices made from",
  "alwaysVisibleInFE": "Always visible in your electronic invoices",
  "amount": "Amount",
  "andLoseSavedData": "and lose saved data",
  "aNewRegulationIsComing": "A new regulation is coming in Colombia! 📢",
  "aNewRegulationIsComing.description": "Starting in April, your customers will be able to request an electronic invoice for sales that exceed <strong>$212,000 before VAT.</strong>",
  "aNewRegulationIsComing.action": "I want to know more",
  "annotations": "observations",
  "anyDocumentSavedInOffline": "Keep selling while your {} is saved or consult the detail in",
  "applyRefund.creditNote": "Apply credit note",
  "applyRefund.adjustmentNote": "Apply adjustment note",
  "architectureConstruction": "Architecture / Construction",
  "areAutomaticallySavedInYour": "are automatically saved in your",
  "areYouSureToDeletePendingInvoice": "Are you sure you want to delete the pending sale?",
  "areYouSureToVoidInvoice": "Confirm that you want to cancel the sale <strong> {} </strong> so that it is removed from your records.",
  "artsCrafts": "Arts / Crafts",
  "AssociatePriceListsToTerminalsNotification.title": "Set up your favorite price list! 💡",
  "AssociatePriceListsToTerminalsNotification.body": "You can set a default price list on your terminal to save time when selling.",
  "AssociatePriceListsToTerminalsNotification.action": "Go set it up",
  "associatedDocument": "Associated document",
  "attributes": "Attributes",
  "attributedRent": "Attributed income",
  "automotiveAndParts": "Automotive and parts",
  "auxiliaryElectronicInvoiceReceipt": "Auxiliary Electronic Invoice Receipt",
  "avoidUsingSpacesAccentsAndSpecialSigns": "Avoid using spaces, accents and special signs",
  "bankAccount": "Bank account",
  "bankAccounts": "Bank accounts",
  "bankAccountsTutorial": "Select the bank to which the money will be deposited according to the method of payment.",
  "banksSetting": "Bank configuration",
  "barcode": "Barcode",
  "base": "Initial base",
  "baseBank": "Base bank",
  "baseBankHelp": "Choose the bank from which the money will come out for the initial base",
  "baseHelp": "Indicate the cash with which you start the shift",
  "basicInformation": "Basic Information",
  "basicInformation.subtitle": "Configura algunos datos necesarios para la generación de tus documentos y transacciones.",
  "basicItemForm": "Basic item form",
  "beforeContinueCompleteFields": "Before continuing, complete the following information.",
  "branch": "Branch office",
  "brand": "Brand",
  "business": "business",
  "businessActivitiesRegime": "Regime of business activities",
  "businessData": "Business data",
  "buyerProductCode": "Buyer's Product Code",
  "cae": "CAE",
  "caea": "CAEA",
  "caeaDueDate": "CAEA due date",
  "caeDueDate": "CAE due date",
  "cancel": "Cancel",
  "cancelItemCreation": "Cancel product creation",
  "cancelItemCreationModalConfirmation": "Do not ask me for confirmation again.",
  "cancelItemCreationModalContent": "Keep in mind that when you confirm the cancellation, the data you have entered for the product will be lost.",
  "cancelItemCreationModalTitle": "Do you want to cancel the creation of this product?",
  "canceled": "Canceled",
  "cannotUpdateProductImage": "Enter Alegra Accounting to change the image of the product.",
  "canton": "Canton",
  "card": "Card",
  "cardType": "Card type",
  "cash": "Cash",
  "cashAmount": "Amount of cash",
  "cashExpectedInClosingShift": "Cash expected in shift",
  "cashIn": "Income",
  "cashInClosingShift": "Cash in closing shift",
  "cashInClosingShift.description": "Initial basis plus sales and cash receipts, less returns and withdrawals.",
  "cashManagement": "Cash management",
  "cashManagementExpenseHelp": "Select the default accounting account to record your expenses from the POS",
  "cashManagementExpenseLabel": "Accounting account for cash outflows",
  "cashManagementIncomeHelp": "Choose the default accounting account for the recording of income other than sales",
  "cashManagementIncomeLabel": "Accounting account for cash receipts",
  "cashManagementIncomeLabel.subtitle": "Choose the accounting account for recording income other than sales",
  "cashManagment": "Cash management",
  "cashOperationsByShifts": "Cash operations",
  "cashOut": "Expenses",
  "cashReturned": "Change",
  "cashSales": "Cash sales",
  "cashToInvoices": "Credit to invoices",
  "categories": "Categories",
  "categoriesNoDataText": "Create your first category quickly and easily. 🚀",
  "categoriesSubtitle": "Create categories to group your products according to their class or type.",
  "category": "Category",
  "categoryDescription": "The categories help you to group your products and services according to their type or class.",
  "categoryImage": "Category Image",
  "categoryType": "Reference type",
  "cause": "Reason",
  "change": "Change",
  "changeCostConfirmationModal.confirm": "Yes, I want to adjust the cost",
  "changeCostConfirmationModal.content.body": "Changing the cost in a decrease in inventory can generate differences in your profitability reports.",
  "changeCostConfirmationModal.content.title": "Do you want to adjust the cost?",
  "changeCostConfirmationModal.title": "Average Cost Adjustment",
  "changeInvoiceError": "Try again",
  "changeItemImage.kit": "Change kit image",
  "changeItemImage.product": "Change product image",
  "changeItemImage.service": "Change service image",
  "changeMethod": "Change method",
  "changePlan": "change plan",
  "characters": "characters",
  "checkIn": "Check in",
  "checkingPayment": "Verifying payment...",
  "checkingQRPayment": "Checking payment status ...",
  "checkInInPOS": "POS billing",
  "checkInSection": "Check in",
  "checkQrCodeError": "This did not go as expected, please try again",
  "checkQRPayment": "Verify payment",
  "checkTheCUIT": "Check your client's CUIT. 🔍",
  "checkTheCUITSubtitle": "Validate that it is well written and if it is correct, we recommend that you complete the data manually.",
  "chooseMyPlan": "Choose my plan",
  "choosePlan": "Choose plan",
  "choosePlanContactUs1": "Do you have doubts about which one to choose?",
  "choosePlanContactUs2": "We help you 👉🏻",
  "choosePlanNote1": "(*) Your Alegra POS plan will depend on your monthly income.",
  "choosePlanNote2": "In all of them you will have Electronic Billing available.",
  "choosePlanSubtitle": "Choose your ideal plan to continue selling easily and quickly in your business",
  "chooseRefundMethod": "Select what type of return you are going to generate for your client",
  "choosePlanSecondaries": "Still can't find the best plan for you?",
  "city": "Town",
  "claroBusinessAccountingSoftwareAccountPoweredByAlegra": "Claro Business Accounting Software Account with Alegra technology",
  "classic": "Classic",
  "clean": "Clean up",
  "cleanSale": "Cancel exit",
  "cleanSaleMessage": "Products will be removed from the current sale",
  "cleanSaleMessageQuestion": "do you wish to continue?",
  "chooseRefundMethodNew": "Choose the type of refund you are going to generate for your client",
  "electronicRefund": "Credit note",
  "clearSelection": "Clear selection",
  "client": "Client",
  "client.accountingOptionsSubtitles": "Choose the accounting account in which the accounts receivable of your credit sales will be registered",
  "client.advancedOptions": "Sale information",
  "client.advancedOptionsSubtitles": "Configure the conditions of sale for your client, such as seller and payment term",
  "client.generalInfoSubtitles": "Enter your client's main information",
  "clientEmail": "Customer email",
  "clients": "Clients",
  "clientInvoice": "Customer invoice",
  "clientPhone": "Customer cell phone",
  "clientIsSyncing": "Client is syncing",
  "clientSyncError": "Client sync failed, please edit the invoice's client before syncing again.",
  "clientSyncErrorMessage": "Client synchronization failed. Learn how to <a class='text-danger font-weight-bold' href='https://www.youtube.com/watch?v=Fl51wZ9ugNE' target='_blank'><u>edit the customer</u></a> on this invoice before syncing it again.",
  "closeBank": "Close bank",
  "close": "Close",
  "closed": "Closed",
  "closeDetail": "Close detail",
  "closeNo": "Closure No",
  "closeShift": "Close shift",
  "closeShiftError": "Try again",
  "closure": "Closure",
  "code": "key code",
  "codeForInternalUse": "Internal use code",
  "colony": "Colony",
  "combined": "Combined",
  "commercialName": "Commercial name",
  "company": "Company",
  "companyName": "Company name",
  "companySetting": "Configure your business",
  "companySettingInfo": "Enter your information and adapt Alegra POS to your business",
  "companySettingSubtitle": "Update your business data that will appear on your invoices",
  "companyUpdatedSuccessfully": "Successfully configured company",
  "companyUpdatedSuccessfullyPrintTitle": "¡That's it! 🎨",
  "companyUpdatedSuccessfullyPrintSubTitle": "The template of your sales documents was successfully edited.",
  "companyUpdateError": "Error when setting up the company",
  "completeKit": "Complete combo",
  "concept": "Concept",
  "configureCompany": "Set up my business",
  "configureCompanyMessage": "To send sales by email you first have to configure the company data",
  "configureFEmodal.title": "Electronic invoicing is here! ✨",
  "configureFEmodal.description":
    "Start to emit electronic invoices in your shop today. <b>Activate them now</b> following the next steps of this tutorial.",
  "configureShifts": "Configure shifts",
  "configureStationWarehouse": "Set warehouse",
  "configureTemplate": "configure template",
  "configureYourCAEANumeration": "Configure your CAEA numeration! ✌",
  "configureYourCAEANumeration.description":
    "To create electronic invoices in your points of sales, you must configure your <b>CAEA contingency numeration</b>, with which you can sell altough AFIP services are down.",
  "configureYourCAEANumeration.button": "Configurate now",
  "configuredPaymentMethodNequiMessage": "Your customers will pay you in seconds by reading the QR with their app.",
  "configuredPaymentMethodTpagaMessage": "Your customers will be able to pay you in seconds from a QR code.",
  "confirm": "Confirm",
  "confirmPlanSubtitle": "Confirm that this is the plan you want to purchase and activate it from the first payment you make in the month of October.",
  "confirmPlanTitle": "¡You are about to choose",
  "confirmVoid": "Confirm cancellation",
  "connectWith": "Connect with",
  "connectYourPointOfSaleWith": "Connect your point of sale with",
  "consulting": "Consultancy",
  "consumptionInvoice": "Consumption Invoice",
  "contactAccountingError": "Something prevented us from bringing the accounting configuration for your client, please reload again.",
  "contactAlreadyExists1": "Contact with identification",
  "contactAlreadyExists2": "already exists. It name is",
  "contactSupport": "contact support",
  "contactUs": "Contact us",
  "contingency": "Contigency",
  "contingencyWarning1": "Remember to send to the DIAN",
  "contingencyWarning2": "from Alegra Accounting",
  "contingencyWarning3": "the equivalent documents that you generated during the day without IVA, know how to do it",
  "continue": "Continue",
  "contractUpdated.success": "Contract updated successfully!",
  "contractUpdated.info1": "Consult or download the contract with",
  "contractUpdated.info2": "* You can access your billing contract from",
  "controlInventory": "Control inventory",
  "controlInventoryHelp": "Activate this option if you want to have quantity control",
  "cost": "cost",
  "costCenter": "Cost center",
  "costCenterSetting": "Cost center",
  "costCenterSettingTutorial": "Find out how to configure your cost centers",
  "country": "Country",
  "createClient": "Create client",
  "createClientError": "Client creation failed",
  "createClientToSelect": "Create a new client to select it",
  "createThisNewClientToSelect": "Create this new client to select it",
  "createCustomField": "Create field",
  "createdUsers": "Created users",
  "createFirstInvoice": "Create first invoice",
  "createInvoiceError": "Invoice creation error",
  "createItemCategory": "Create category",
  "createItemError": "Product creation error",
  "createItemSubmitError1": "Configure the variants section.",
  "createItemSubmitError1Message":
    "From the variants section you must select at least one option and indicate the quantity available in the warehouse of the ones you choose.",
  "createItemSubmitError2": "Add the products that make up your combo.",
  "createItemSubmitError2Message":
    "You will be able to assign the amount of each one to start selling it and have control of the available units and income.",
  "createKit": "Create combo",
  "createKitHelp": "Group in a single item a set of products, services or a combination of both.",
  "createPaymentError": "Error creating payment",
  "createPaymentTermError": "Error in the creation of the payment term",
  "createPriceList": "Create list",
  "createPriceListError": "Error creating the price list",
  "createProduct": "Create item",
  "createProductHelp": "Create the goods and merchandise that you sell and include all their details.",
  "createRefundError": "Error creating return",
  "createSellerError": "Error creating the seller",
  "createService": "Create service",
  "createServiceHelp": "Create the commercial or consulting activities that you offer to your clients.",
  "createVariant": "Create variant",
  "createVariantError": "There was an error creating the new variant.",
  "createVariantHelp": "Create a new feature with your options, such as different colors or sizes.",
  "createWarehouse": "Create warehouse",
  "createWarehouseError": "Error creating the warehouse",
  "createdAt": "Created at",
  "creation": "Creation",
  "credit": "Credit",
  "credit-card": "Credit",
  "creditBank": "Credit bank",
  "creditCard": "Credit card",
  "creditNoteType": "Type of credit note",
  "creditPaymentSales": "Credit sales",
  "creditSales": "Credit card sales",
  "creditToInvoice": "Invoice credit",
  "cude": "CUDE",
  "cufe": "CUFE",
  "CUITIdentificationIsANumericalValue": "The CUIT must be a numeric value, it can have hyphens",
  "currency": "Currency",
  "currenciesSettings": "currency settings",
  "currentPlan": "Current plan",
  "currentPOSPlan": "Current plan",
  "currentQuantity": "Current quantity",
  "currentShift": "Shift in progress",
  "currentShiftDetails": "Details of the current shift",
  "custom": "Custom",
  "customFieldActivated": "Additional field activated successfully",
  "customFieldCreatedSuccessfully": "It's saved. 😎",
  "creditToSales": "Credit to sales",
  "customFieldCreatedSuccessfullyMessage": "Your additional field {} is available to associate with your products.",
  "customFieldDeactivated": "Additional field deactivated successfully",
  "customFieldDeleted": "Additional field removed successfully",
  "customFieldDeletedError": "Error removing additional field",
  "customFieldNoDataText": "Create your first additional field now. 🙌",
  "customFields": "Additional fields",
  "customFieldsInventoryInfo": "Create additional fields to add extra information to your items, such as barcodes.",
  "customFieldStatusError": "Error changing the state of the additional field",
  "customFieldsSubtitle": "Create additional fields to add additional information about your products or services.",
  "customFieldType": "Field type",
  "customFieldType.text": "Text",
  "customFieldType.int": "Number",
  "customFieldType.decimal": "Decimal number",
  "customFieldType.date": "Date",
  "customFieldType.boolean": "Yes/No",
  "customFieldType.optionsList": "Options list",
  "customFieldTypeHelp": "With this option you can define the structure and format of your field.",
  "dailyReport": "Daily report",
  "dailyReportDescription": "Export the <a href='https://ayuda.alegra.com/es/conoce-el-comprobante-de-informe-diario-del-sistema-punto-de-venta-Colombia' target='_blank' rel='noreferrer'>daily summary</a> of your registered sales in equivalent documents.",
  "dataOffline": "Synchronization pending",
  "dataOfflineSubtitles": "You can continue selling and we will synchronize your invoices when you are back online.",
  "date": "Date",
  "dateDisabledTooltip": "The creation date of the note must be the same as the emission date for it to be accepted by the DIAN.",
  "dateFormat": "MM/DD/YYYY",
  "dateFormatDateFNS": "MM/dd/yyyy",
  "dateTimeFormat": "MM/DD/YYYY h:mm a",
  "day": "day",
  "days": "days",
  "daysQuantity": "Number of days",
  "deactivate": "Deactivate",
  "debit": "Debit",
  "debit-card": "Debit",
  "debitBank": "Debit bank",
  "debitCard": "Debit",
  "debitSales": "Debit card sales",
  "debtToPay": "Bill to pay",
  "decimalPrecision": "Decimal precision",
  "decimalPrecisionTooltip": "Select the number of decimal places that you will use for the prices of your products and values of your invoices",
  "decimalSeparator": "Decimal separator",
  "decrement": "Decrease",
  "defaultCashBankTutorial": "This bank account is assigned by default to each terminal so that you have better control of your cash.",
  "defaultClientIVAError":
    "Remember that to issue electronic invoices with products without IVA, you must create your client with their identification",
  "defaultSeller": "Default seller",
  "defaultValue": "Default value",
  "defaultValueHelp.customField": "Define the default value that this field will take when associated with a product.",
  "defaultValueHelp.customField.boolean": "Check this option so that this field activates when associated with a product.",
  "delete": "Delete",
  "deleteInventoryAdjustment": "Delete adjustment",
  "deleteInventoryAdjustment.info": "Note that deleting <b>the inventory adjustment # {}</b> the variation of quantities that had been generated in your products will be deleted. ",
  "deleteInventoryAdjustment.modalTitle": "Delete inventory adjustment",
  "deleteInventoryAdjustments": "Delete adjustments",
  "deleteInventoryAdjustments.info": "Note that deleting <b>the selected inventory adjustments</b> the variation of quantities that had been generated in your products will be deleted.",
  "deleteInventoryAdjustments.modalTitle": "Delete inventory adjustments",
  "deletePendingInvoice": "Delete pending sale",
  "deliveryTerm": "Terms of delivery",
  "department": "Department",
  "description": "Description",
  "detail": "Detail",
  "digitalSignature": "Digital signature",
  "dianValidation": "DIAN validation",
  "disable": "Deactivate",
  "disableMulticurrency": "Disable multi-currency",
  "disablePendingInvoices": "Disable pending sales",
  "disableShifts": "Disable cashier shifts",
  "discount": "Discount",
  "discountCalculationHelTooltip": "The discount will be calculated on the base price before<br/>taxes, learn",
  "discountCalculationHelTooltip.link": "https://ayuda.alegra.com/en/edita-tus-productos-mientras-vendes-pos",
  "discountCalculationHelTooltip.linkText": "more about discounts",
  "discountNature": "Nature of the discount",
  "discoverAvailablePlans": "Discover all the available plans for you!",
  "distributeInWarehouses": "Distribute in warehouses",
  "district": "District",
  "DNIEnteredDoesNotExistOrIsInvalid": "The DNI entered does not exist or contains invalid data, verify the information and make the query again or continue adding the data manually.",
  "document": "Document",
  "download": "Download",
  "doYouWantToCreateAnotherContactWithSameId": "Are you sure you want to create another contact with that identification number?",
  "dueDate": "expiration",
  "dv": "DV",
  "dvTooltip": "This field is loaded automatically according to the verification code assigned by the DIAN to your identification number",
  "eCommerce": "E-commerce",
  "economicActivity": "Economic activity",
  "economicActivityTooltip": "Select all your economic activities and mark one as preferred from the ⭐",
  "edit": "Edit",
  "editInventoryAdjustment.title": "Edit inventory adjustment",
  "editInventoryAdjustment.subtitle": "Change the data of the inventory adjustment that you had created.",
  "editInvoice": "Edit invoice",
  "editInvoiceSettingsError": "Failed to change settings",
  "editItem": "Edit product",
  "editItemError": "Product edition error",
  "editItemSuccessfully": "Your changes have been saved!",
  "editVariant": "Edit variant",
  "editVariantHelp": "General settings cannot be edited on variants that have associated items, you can only add new options.",
  "editPaymentWarning": "The new value will not be reflected in the income for the current shift.",
  "editTip": "Edit tip",
  "editTip.tooltip": "This suggestion can be a maximum of 10% according to the SIC, and your client can choose not to accept it or modify its value",
  "education": "Education",
  "electronicDocument": "Electronic document",
  "electronicInvoice": "Electronic bill",
  "electronicInvoiceSaved": "Electronic invoice saved",
  "electronicMail": "Email",
  "electronicPaymentsTotalMustBeLTETotal": "The total of electronic payments must be equal to or less than the total of the invoice",
  "electronicPOS": "Electronic POS",
  "electronicPOSDocument": "Electronic POS document",
  "electronicPOSDocumentSaved": "Electronic POS Document saved",
  "electronicPOSDocument.notification.title": "The electronic POS document has arrived 📢",
  "electronicPOSDocument.notification.text": "Enable yourself in minutes to issue this type of document and keep your business up to date with the DIAN.",
  "electronicSalesInvoice": "Electronic bill of sale",
  "electronicSellInvoice": "Electronic invoice",
  "electronicTicket": "Electronic ballot",
  "email": "Email",
  "emissionDate": "Emission date",
  "emissionStatus": "emission status",
  "emissionType": "Emission type",
  "emit": "Emit",
  "employeeRegime": "Employee regime",
  "enable": "Activate",
  "enablements": "Enablements",
  "enableMulticurrency": "Enable multi-currency",
  "enablePendingInvoices": "Enable pending sales",
  "enableShifts": "Enable cashier shifts",
  "endDate": "Deadline",
  "enterAmount": "Enter amount",
  "enterAName": "Please enter a name",
  "enterAnEmail": "Enter an email",
  "enterAnID": "Enter an ID",
  "enterAValidEmail": "Enter a valid email",
  "enterAValidIdentification": "Enter a valid identification",
  "entertainment": "Entertainment",
  "equivalentDocumentPOSSystem": "P.O.S. system",
  "error": "Error",
  "examiner": "Examiner",
  "exchangeRate": "Exchange rate",
  "exemptSubtotal": "Exempt Subtotal",
  "expectedAmount": "Expected cash on hand",
  "expectedCash": "Actual cash on hand",
  "expectedCashHelp":
    "In case it is different than expected at the cash register, you can make a cash deposit or withdrawal while you validate the reason for it.",
  "expectedCashShouldBeEqualToTotal":
    "The real cash value must match the expected money, you can make a cash deposit or withdrawal while you validate and leave the observation.",
  "expenseCash": "Cash withdrawals",
  "expired": "Expired",
  "export": "export",
  "exteriorNumber": "Exterior number",
  "externalPaymentDeclined": "payment has been declined",
  "fastOptions": "Quick options",
  "feArgError": "Check your invoice details",
  "feArgValidateError": "Check that the type of document with your number corresponds to your IVA status and that of your client.",
  "feArgValidateErrorMore": "More information by clicking",
  "feArgValidateErrorTitle": "Your invoice will not be issued by the type of document",
  "feeRegimeProfessionalServices": "Fee scheme (professional services)",
  "feNumeration": "Electronic billing number",
  "fieldsMarkedRequired": "Fields marked with <span class='text-primary'>*</span> are required",
  "filter": "Filter",
  "filter.active": "Active",
  "filter.inactive": "Inactive",
  "filters": "Filters",
  "finalPrice": "Final price",
  "finalPriceHelp": "It is calculated automatically or enter it to find the price without taxes",
  "finalQuantity": "Final quantity",
  "finalQuantityAbbreviation": "Final amt.",
  "firstName": "First name",
  "firstYouNeedTypeCloseToCloseSession": 'To log out, first confirm by typing the word "Close"',
  "fiscalIncorporationRegime": "Tax Incorporation Regime",
  "fiscalResponsabilities": "Fiscal responsibilities",
  "foodGastronomy": "Food / Gastronomy",
  "forMakingInvoicesThatHasBeenOptimizedForPointsOfSale": "is an agile billing system, optimized for points of sale",
  "from": "from",
  "fullName": "Full name",
  "fvArgError": "Check your invoice details",
  "fvArgValidateError": "Check that the type of document with your number corresponds to your IVA status and that of your client.",
  "fvArgValidateErrorMore": "More information by clicking",
  "fvArgValidateErrorTitle": "Your invoice will not be created by the type of document",
  "generalConsumption": "General consumption",
  "generalInfo": "General data",
  "generalFeatures": "General features",
  "generalPOSFeatures": "General POS features",
  "generalPOSFeatures.desc": "Configure the functionalities that you are going to use in all the terminals of your point of sale",
  "generalStationInfoTutorial": "Configure your station data and information needed to create your documents",
  "generalInfoTutorial": "Set up your business information",
  "generalRegime": "General scheme",
  "generalRegimeOfTheLawForLegalEntities": "General Regime of Legal Persons",
  "generateQR": "Generate QR code",
  "generated": "Generated",
  "generatingYourContract": "We are generating your contract.⏳",
  "generatingYourContract.description": "In a few seconds you can finish accepting the new terms and conditions.",
  "generationDate": "Generation date",
  "geographicCoordinates": "Geographic coordinates",
  "getCED": "Search citizenship card",
  "getCUIT": "Search CUIT",
  "getDNI": "Search DNI",
  "getID": "Search ID",
  "getIntoOtherTerminal": "Enter another terminal",
  "getNIT": "Search NIT",
  "getToKnowAlegra": "Get to know Alegra Accounting",
  "getQrCodeError": "This did not go as expected, please try again",
  "getRNC": "Search RNC",
  "getRUC": "Search RUC",
  "goBack": "Return",
  "goToAlegra": "Go to Alegra",
  "goToAlegraAccounting": "Go to Alegra Accounting",
  "goToAdvancedForm": "Go to advanced form",
  "goToCheckIn": "Go to check-in",
  "goToMenu": "Go to menu to",
  "goToSales": "Go to sales",
  "goToStart": "Go to start",
  "goToSubscriptions": "Go to subscriptions",
  "goToTutorial": "Go to tutorial",
  "governmentInvoice": "Government Invoice",
  "groupData": "Group data",
  "here": "here",
  "hideDescription": "Hide description",
  "hideLogo": "Hide logo",
  "hideUnitPrice": "Hide unit prices",
  "history": "History",
  "shiftHistory": "Shift history",
  "happyWeekTitle1": "Save with the",
  "happyWeekTitle2": "Happy Week!",
  "happyWeekSubtitle1": "Pay",
  "happyWeekSubtitle2": "2 month",
  "happyWeekSubtitle3": "of Alegra POS and receive the",
  "happyWeekSubtitle4": "50% OFF",
  "happyWeekSubSubtitle": "* Valid from May 9 to 16.",
  "happyWeekTerms": "* This discount is only available for Pyme, Pro & Plus plans",
  "hiWeWelcomeYou": "Hello, we welcome you!",
  "hotelsTourism": "Hospitality / Tourism",
  "howToEmit": "How to stamp them",
  "id": "ID",
  "IDEnteredDoesNotExistOrIsInvalid":
    "The identification entered does not exist or contains invalid data, verify the information and redo the query or continue adding the data manually.",
  "identification": "Identification",
  "identificationEnteredDoesNotExistOrIsInvalidTitle": "Check your client ID. 🔍",
  "identificationEnteredDoesNotExistOrIsInvalidSubtitle": "Verify that it is spelled correctly and if it is correct, we recommend adding the data manually.",
  "identificationNumber": "Identification number",
  "identificationType": "Identification Type",
  "ifYouCloseSessionThenYouLostData": "You can cancel to sync them and avoid losing them permanently.",
  "itemsLoadError": "Sorry, we could not load items from company, try again",
  "imageSelector": "Image selector",
  "imageTooLargeError": "An error occurred while receiving the file, check that the image size is less than 2MB",
  "imABusinessman": "I'm a businessman",
  "imAnAccountant": "I am an accountant",
  "imAStudent": "I am a student",
  "imFreelancerIndependent": "I am a freelancer / independent",
  "immediatePaymentSale": "Cash sales",
  "inactive": "Inactive",
  "inactivePaymentMethod": "Payment method deactivated",
  "inactiveProducts": "Inactive",
  "inAmount": "Entered value",
  "includeAccountStatement": "Include account statement",
  "includeAccountStatementSubtitle": "When sending an invoice to the mail, it will be accompanied by the summary of all your invoices",
  'includeTip': 'Include tip',
  "income": "Income",
  "incomeLimitNotification":
    "Has excedido el límite de ingresos mensuales de  tu plan actual, elige un nuevo plan para realizar esta acción y seguir creciendo con Alegra POS.",
  "incomesTo": "Income up to",
  "increment": "Increase",
  "indicateTheVariantQuantity": "Indicate here the quantities in your warehouses",
  "indicationsWillArriveToEmail": "The step by step to configure your payment method will arrive in the mail",
  "initAppError": "Oops! POS could not be loaded",
  "initAppErrorMessage": "Please try one of the following actions:",
  "initAppOfflineDataErrorMessage": "There is data that has not been synchronized belonging to another terminal. If you continue, you could lose them.",
  "initialQuantity": "Initial amount",
  "initialQuantityAbbreviation": "Init. amt.",
  "inOctober": "in October",
  "inOutCash": "New cash flow",
  "inPayment": "entry",
  "inProgress": "In progress",
  "interiorNumber": "Interior number",
  "internalOperationInvoice": "Internal operation invoice",
  "inType": "Entry",
  "inventariable": "Inventory",
  "inventory": "Inventory",
  "inventoryAdjustment": "Inventory adjustment",
  "inventoryAdjustments": "Inventory adjustments",
  "inventoryAdjustmentstInfo": "Register manual increases and decreases to control the quantities of your products.",
  "inventoryAdjustmentCreateError": "Error creating inventory adjustment",
  "inventoryAdjustmentCreated.title": "Great! ⚖",
  "inventoryAdjustmentCreated.subtitle": "Inventory adjustment <span class='font-weight-bold'># {}</span> has been saved, and quantity movements have been made.",
  "inventoryAdjustmentCreatedLocally.title": "Created as pending! 📌",
  "inventoryAdjustmentCreatedLocally.subtitle": "The adjustment will be saved at your point of sale when you recover your connection.",
  "inventoryAdjustmentDeleted.title": "Your adjustment has been deleted 🗑",
  "inventoryAdjustmentDeleted.subtitle": "Inventory adjustment <span class='font-weight-bold'># {}</span> is no longer part of your records.",
  "inventoryAdjustmentsDeleted.title": "Your adjustments has been deleted 🗑",
  "inventoryAdjustmentsDeleted.subtitle": "The inventory adjustments you selected are no longer part of your records.",
  "inventoryAdjustmentDeletedLocally.title": "Programmed deletion! 🗑",
  "inventoryAdjustmentDeletedLocally.subtitle": "The adjustment will be permanently deleted as soon as your connection is restored.",
  "inventoryAdjustmentEdited.title": "Changes are ready! ✨",
  "inventoryAdjustmentEdited.subtitle": "Inventory adjustment <span class='font-weight-bold'># {}</span> is now in the form you gave it.",
  "inventoryAdjustmentEditedLocally.title": "Pending changes ✍",
  "inventoryAdjustmentEditedLocally.subtitle": "The edited adjustment data will be saved when you recover your connection.",
  "inventariableHelp": "Distribute your products among your warehouses",
  "inventoryWarehouses": "Inventory warehouses",
  "invoice": "invoice",
  "invoiceCash": "Cash income",
  "invoiceMethod": "Payment method",
  "invoiceNFC": "Invoice / NCF",
  "invoiceNumbering": "Invoice numbering",
  "invoiceNumberError": "There is already an invoice with the number of this receipt, know how to <a href='https://app.powerbi.com/view?r=eyJrIjoiZDdmNTBlYTktYmE0Ni00NGM3LWJhNzYtMGZhNjBlNTQyYTdmIiwidCI6IjYxOGQwYTQ1LTI1YTYtNDYxOC05ZjgwLThmNzBhNDM1ZWU1MiJ9' target='_blank' rel='noreferrer'><b>edit the current numbering</b></a> or select another one before selling",
  "invoiceOfSale": "Sales invoice",
  "invoicePoint": "Invoice point",
  "invoicePreview": "Invoice preview",
  "invoicePrint": "Invoice Print",
  "invoicePrintFooter": "Generated in Alegra POS - alegra.com/pos",
  "invoicePrintFooterClaro": "Generated in Claro Business Accounting Software with Alegra technology - software-contable.com.co",
  "invoicePrintSetting": "Configure your print template",
  "invoicePrintSettingSubtitle": "Choose the parameters that you will use and they will be visible on your invoices.",
  "invoiceSaved": "Saved sale",
  "invoicesCreatedInTheMonth": "Invoices created in the month",
  "invoiceSavedInOffline": "Keep selling while your invoice is saved or consult the detail in",
  "invoiceSendByEmailError": "Error trying to mail the sale",
  "invoiceSendByEmailSuccessfully": "Has been sent successfully",
  "invoiceSettings": "Sales setup",
  "invoicesInfo": "set up the sales documents you use in your business",
  "invoicesLoadError": "Sorry, we could not load the invoices from the station",
  "invoicesPerMonth": "invoices per month",
  "invoicesSettingInfo": "set up the sales documents you use in your business",
  "invoiceStampedSuccess": "Electronic invoice was stamped successfully",
  "invoiceStatusclosed": "Paid",
  "invoiceStatusopen": "Pending",
  "invoiceStatusvoid": "Void",
  "invoiceStatusdraft": "Draft",
  "invoiceSyncError": "Synchronization failed",
  "invoiceSyncErrorOffline": "It will be saved when you have internet connection ☝️",
  "invoiceSyncErrorOfflineSubtitle": "You will be able to consult the invoice in the sales history as soon as you recover your connection.",
  "invoiceSyncErrorSubtitle": "More details",
  "invoiceType": "Invoice type",
  "invoiceWithoutClientError": "The generic contact could not be associated, please try to invoice again",
  "invoices": "Invoices",
  "isMomentToChoseYourPlan": "It's time to choose your ideal plan to continue selling easily and quickly in your business.",
  "item": "Product",
  "itemActivated": "Successfully activated item",
  "itemAlreadyInactive": "Item {} was already deactivated",
  "itemWithNameActivated": "Item {} activated successfully",
  "itemWithNameDectivated": "Item {} deactivated successfully",
  "item.accountingOptions": "Accounting setup",
  "item.accountingOptionsSubtitles": "Choose the account where the income from this product will be recorded",
  "item.adicionalDataOptions": "Additional data for export invoices",
  "item.adicionalDataOptionsSubtitles": "Complete this information if the product you are creating will be used in export invoices.",
  "item.advancedOptionsSubtitles": "Add the barcode and description of your products",
  "item.electronicFieldsOptions": "Additional fields",
  "item.electronicFieldsOptionsSubtitles": "Add additional information to personalize your service.",
  "item.generalInfo": "General information",
  "item.generalInfo.subtitle": "Configure the main information and contact details of your business.",
  "item.generalInfoSubtitles": "Indicate the basic data of your product or service",
  "item.inventoryOptions": "Inventory details",
  "item.inventoryOptionsSubtitles": "Select the products and their quantities to put together a combo.",
  "item.kitListOptions": "Combo",
  "item.kitListOptionsSubtitles": "Distribute and control the quantities of your products in different places.",
  "item.priceListOptions": "Price list",
  "item.priceListOptionsSubtitles": "Add different prices to your products.",
  "item.variantsListOptions": "Variants",
  "item.variantsListOptionsSubtitles": "Add attributes like size, color or other quality to categorize your products.",
  "items": "Products",
  "itemsAndServices": "Products and services",
  "itemsAssociatedToInvoice": "Products associated with your invoice",
  "itemStatusError": "Error changing item status",
  "itemCategoryActivatedSuccessfully": "Category activated successfully.",
  "itemCategoryCreatedSuccessfully": "This is the way.",
  "itemCategoryCreatedSuccessfullyMessage": "Category {} is now available for you to classify your products.",
  "itemCategoryDeactivatedSuccessfully": "Category deactivated successfully.",
  "itemCategoryDeletedSuccessfully": "The category has been deleted.",
  "itemCategoryDeletedError": "Error removing category.",
  "itemCategoryStatusError": "Error changing category status.",
  "itemCategoryUpdatedSuccesfully": "Category updated successfully.",
  "itemCategoriesAreEmpty": "The categories you selected are empty in the warehouse of this station. 🔍",
  "itemCategoryIsEmpty": "The category {} it's empty in the warehouse of this station. 🌪️",
  "itemCreatedSuccessfully": "Straight on target.",
  "itemCreatedSuccessfullyAlter": "Your product has been created.",
  "itemCreatedSuccessfullyMessage": "{} {} is now available in your account and is ready for sale.",
  "itemDeactivated": "Item disabled successfully",
  "itemCreatedSuccessfullyMessageAlter": "Remember that you did not associate it with the warehouse of your current terminal, and to sell it, ",
  "itemCreatedSuccessfullyMessageLink": "you must change the terminal or edit it.",
  "itemDescriptionHelp": "Classify your products and services by types or groups",
  "itemDeleted": "Product successfully deleted",
  "itemDeletedError": "Deleting product error",
  "itemImage": "Product image",
  "itemImage.kit": "Kit image",
  "itemImage.product": "Product image",
  "itemImage.service": "Service image",
  "itemManagementInfo": "Create, edit and manage every detail of the products or services you sell.",
  "itemLimitWarningTitle": "You already sold all the units. 🏁",
  "itemLimitWarningTitle.kit": "Check the kit products 🔍",
  "itemLimitWarningTitle.kitAvailable": "You can sell maximum {} kits! ✋",
  "itemLimitWarningSubtitle.kitAvailable": 'Keep in mind that your Kit "{}" contains products with that number of units available.',
  "itemLimitWarningSubtitle": "Check if you have a pending purchase to register or edit this product and activate the negative sales option",
  "itemMinimumQuantityReached": "We reach the minimum quantity, remember to stock up",
  "itemLimitWarningSubtitle.kit": 'The "{}" kit cannot be sold because it has products with no available units.',
  "itemQuantityIsGreaterThanAvailable": "Quantity sold exceeds what is available in inventory",
  "itemsAreLoadingInBackground": "We continue to load your products, we are sorry that it is taking longer than expected",
  "itemAdded": "Item added",
  "itemsAdded": "Items added",
  "itemsInitialLoad": "Loading your products, give us one more time",
  "itemsInSaleExceededReference": "One/some of your products for sale exceeds the maximum 20 characters for the reference, edit it to continue.",
  "itemsPerPage": "Items per page",
  "itemsSearching": "looking for the items",
  "itemVariantHelp": "Indicate here the quantities in your warehouses",
  "itemsToAddInDevolution": "Products to include in your customer's return",
  "itemsToAddInDevolution.creditToSales": "Choose the products from the associated document in your customer's return",
  "itemsToAdjust": "Products to adjust",
  "ivaCondition": "IVA condition",
  "joyride.back": "Back",
  "joyride.close": "Close",
  "joyride.last": "Last",
  "joyride.next": "Next",
  "joyride.open": "Open tour",
  "joyride.skip": "Skip",
  "keepInMind": "Keep in mind",
  "KeepTheFormEmail": "Keep the form: example@email.com",
  "keyboardShortcuts": "Keyboard shortcuts",
  "kindOfPerson": "Kind of person",
  "kit": "Combo",
  "kitKey": "Product code",
  "kitName": "Combo name",
  "kits": "kits",
  "knowMore": " Know more.",
  "knowRecentChanges": "Learn about recent changes",
  "knowTheFirstStepsInPOS": "Meet the first steps in POS",
  "knowTheNewRegulation": "Learn about the new regulation for points of sale! 🤓",
  "knowTheNewRegulation.description": "From April 1, electronic invoices must be created instead of equivalent documents in sales that exceed $212,000",
  "knowTheNewRegulation.action": "I want to know more",
  "knowUpdatedData": "Know updated data",
  "lastDate": "Last date",
  "lastName": "Surnames",
  "later": "Later",
  "leasingRegime": "Leasing scheme",
  "learnToCreateIt": "Learn how to create it",
  "learnToUseShifts": "Learn how to use shifts",
  "learnToUseShifts.link": "https://ayuda.alegra.com/es/realiza-una-apertura-y-cierre-de-turnos-en-el-sistema-punto-de-venta-pos",
  "legalPerson": "Legal person",
  "legalRepresentativeData": "Info about legal representative",
  "legalRepresentativeData.tooltip": "It is the person who acts on behalf of the company and is registered in the RUT",
  "legalStatus.inProcess": "In process",
  "legalStatus.issued": "Issued",
  "legalStatus.issued.alter": "Issued",
  "legalStatus.rejected": "Rejected",
  "legalStatus.toBeIssued": "To be issued",
  "legalStatus.contingency": "Contingency",
  "limitedPlanNotification": "Update your subscription and don't stop your billing!🚨",
  "limitedPlanNotification.description": "You have exceeded the characteristics of your plan and as of Monday, March 13, you will only be able to see the information created up to that day.",
  "limitedPlanNotification.action": "Update subscription",
  "limitedQueryPlan": "Update your subscription and don't stop your billing!🚨",
  "limitedQueryPlan.description": "You are in a Consultation Plan and as of Monday, March 13, you will only be able to see the information created up to that day.",
  "limitedQueryPlan.action": "Update subscription",
  "loadingQRPayment": "Generating code for payment ...",
  "loadingResourcesSubtitle": "You can sell while your products and clients load",
  "localData": "Local data",
  "locality": "Locality",
  "logoImageSize": "178x53 pixels",
  "logout": "Sign off",
  "logoutMessage": "Check data synchronization before closing",
  "logoutMessageQuestion": "Do you want to log out?",
  "logoSubtitle": "It will be displayed at the top of your sales invoices.",
  "logoSubtitle.text1": "If you need help, you can see",
  "logoSubtitle.text2": "how to add your logo",
  "mainSale": "Main sale",
  "makeSureYouAreRegisteredWithTheLastStation": "Make sure you are registered in the last station that was worked with",
  "makeSureYouHaveTheNecessaryPermissions": "Make sure you have the necessary permissions to enter the POS",
  "makeVisible": "Make visible",
  "manageShifts": "Manage shifts",
  "manageShiftsHelp": "It allows you to control your operations for defined periods of time",
  "manageStations": "manage stations",
  "manufacture": "Manufacture",
  "maxCharactersExceeded": "Maximum {} characters",
  "maxDiscountExceeded": "Maximum discount: {}%",
  "maxQuantity": "Maximum amount",
  "maxQuantityHelp": "Receive alerts when you reach the maximum stock you indicate.",
  "measurement": "Measurement",
  "measurementUnit": "Unit of measurement",
  "membershipError": "It seems that an error occurred 🙁. We are fixing it please try again later or contact support",
  "messageDeleteMainActivity": "Before deleting this activity you must mark the one that will remain as preferred.",
  "minQuantity": "Minimum amount",
  "minQuantityHelp": "Receive alerts when you reach the minimum stock you indicate.",
  "mobilePhone": "Mobile phone",
  "model": "Model",
  "modern": "Modern",
  "month": "Month",
  "monthly": "Monthly",
  "monthlyPlanButton": "Monthly",
  "moreInfo": "More info",
  "moreSettingInfo": "You can find more functionalities for your business in Alegra Accounting",
  "moreSettings": "More settings",
  "multicurrency": "Multicurrency",
  "multicurrency.subtitle": "Register your sales documents in different currencies",
  "multicurrencyField": "Use multicurrency",
  "multicurrencyFieldHelp": "Generate your POS invoices in different currencies",
  "municipality": "Municipality",
  "myBusiness": "My business",
  "myCompany": "My company",
  "myCustomFields": "My additional fields",
  "myProfile": "My profile",
  "mySellers": "My sellers",
  "mySubscription": "My subscription",
  "myWarehouses": "My warehouses",
  "mypeTaxRegime": "MYPE tax regime",
  "myVariants": "My variants",
  "name": "Name",
  "nameOrSocialReason": "Name or social reason",
  "naturalPerson": "Natural person",
  "needConnectionToEditAdjustment": "You need to be online to edit an adjustment.",
  "negativeSale": "Sell without available units",
  "neighborhood": "Neighborhood",
  "nequi": "Nequi",
  "networkErrorMessage": "Check your connection and try again",
  "new": "New",
  "new.alter": "New 🔥",
  "newCategory": "New category",
  "newClient": "New client",
  "newClientSubtitle": "Create the contacts that you will associate in your sales invoices.",
  "newCurrency": "new currency",
  "newCustomField": "New additional field",
  "newCustomFieldModalSubtitle": "Add additional information to personalize your products and services.",
  "newCustomFieldModalTitle": "New additional field",
  "newInventoryAdjustment": "New inventory adjustment",
  "newInventoryAdjustmentSubtitle": "Modify the quantities of the products you have in the warehouse of your current terminal.",
  "newItem": "New product or service",
  "newItemButton": "New product",
  "newItemClothingStores": "New product",
  "newItemCategory": "New category",
  "newItemCategoryModalTitle": "New category",
  "newItemCategoryModalSubtitle": "Create new categories to classify your products and locate them easily.",
  "newItemSubtitle": "Create the products or services that you offer in your business.",
  "newPaymentTerm": "New payment term",
  "newPriceList": "New list",
  "newPriceListModalTitle": "New price list",
  "newPriceListModalSubtitle": "Create a list to use a different price on your products or services.",
  "newRefund": "New refund",
  "newRefundRefundCashMethodDescription": "Refund your client the full or partial amount of their payments.",
  "newRefundCreditToSaleshMethodDescription": "Decrease the outstanding balance on your client's invoice.",
  "newRefundCreditToSaleshMethodDescriptionFE": "Decrease the outstanding balance on your client's invoice or electronic POS document.",
  "newRefundCombinedMethodDescription": "Refund money to your client while simultaneously applying credit to one of their sales.",
  "newrefundPositiveBalanceMethodDescription": "Record the refund of products and leave the value as a credit for your client.",
  "newRegulationForPOS": "New regulation for points of sale! 📢",
  "newRegulationForPOS.description": "From April 1, your sales in POS documents that exceed <strong>$212,000 before VAT</strong> must be electronically invoiced.",
  "newRegulationForPOS.action": "Comply from now",
  "newRUS": "New RUS",
  "newSale": "New sale",
  "newSeller": "New seller",
  "newStation": "New station",
  "newVariant": "New variant",
  "newTermsAndConditions": "New terms and conditions",
  "newTermsAndConditionsHere": "Here you can take a look to the contract",
  "newWarehouse": "New warehouse",
  "newWarehouseSubtitle": "Create all your storage points from your inventory",
  "next": "Next",
  "nextPaymentDate": "Next payment date",
  "NITEnteredDoesNotExistOrIsInvalid":
    "The NIT entered does not exist or contains invalid data, verify the information and redo the query or continue adding the data manually.",
  "no": "No",
  "noCashAvailable": "The value of the withdrawal must be less than the cash available in the box",
  "noCustomFieldType": "Please choose a field type",
  "noDataFound": "There is no data that matches the applied filters",
  "noDataOffline": "Synchronization up to date",
  "noDataOfflineSubtitles": "All your records are synchronized",
  "noDate": "Choose a date",
  "noEligibleStation": "No stations to choose from",
  "noEligibleStationMessage": "Verify that there is at least one active station",
  "noFiscalValue": "No tax value",
  "noInventoryAdjustmentsFound.title": "No results 🔦",
  "noInventoryAdjustmentsFound.subtitle": "Adjust the filters to find what you're looking for.",
  "noITBMS": "Includes the ITBMS tax to invoice this item.",
  "noItbmsWarning.summary": "It seems that you selected products that do not have ITBMS taxes and you must validate them to create your invoice.",
  "noIvaDay": "Day without IVA",
  "noIVADayItemsLimitWarning":
    "This client has already acquired the 3 units of this category that are exempt and in the next one they will be charged IVA",
  "noIVADayItemsLimitWarningInEdit1": "We recommend charging this product with IVA, because you have already selected the",
  "noIVADayItemsLimitWarningInEdit2": "3 exempt units",
  "noIVADayItemsLimitWarningInEdit3": "that belong to this category",
  "noIVADaysNotification": "Get ready for the day without IVA",
  "noIVADaysNotification2": "Prepare your contingency billing",
  "noItbisWarning.items": "Include the ITBIS tax of this product to bill",
  "noItbisWarning.summary": "It seems that you selected products that do not have ITBIS taxes and you must validate them to create your invoice.",
  "noItemsInInventoryAdjustmentError": "You must add at least one product to your adjustment",
  "notItemsYet": "To create your first invoice we invite you to add a product or service. 🏷️",
  "none": "None",
  "notItemsYetClothingStores": "Add a product to associate it on your first invoice. 👔👚",
  "notValidInvoiceArg": "Invoice not valid as tax receipt",
  "nonGovernmentalOrganization": "Non Governmental Organization",
  "noProductsSatisfySearch": "There are no products that satisfy the search",
  "noResultsElectronic": "You do not have any electronic credit note numeration",
  "noResultsNoElectronic": "You do not have any credit note numeration",
  "noResultsSaleTicket": "You do not have any adjustment note numeration",
  "noResultsWereFound": "No results found",
  "noSelectedAccountForTips": "Choose an accounting account in order to activate the functionality",
  "noSelectedInvoice": "Select an invoice to know the detail or create a new sale now.",
  "noSelectedPayment": "Select a cash operation to see the detail or create a new one",
  "noSelectedQRPayment": "Select at least one payment from the list to see the detail or create the first one here",
  "noSelectedRefund": "Choose a refund to know the detail or create a new one now.",
  "noShiftInQuery": "Select a new period that has closed shifts.",
  "noShiftsConfigured": "Shifts are intervals of time that are used to manage your operations in your company. Start using them",
  "noStationPermissionError": "Do you need access to this station?",
  "noStationPermissionWithNameError": "Do you need to enter the terminal{}?",
  "noStationPermissionErrorMessage": "Request access to an administrator profile or log in from another terminal where you have permission.",
  "noStationPermissionErrorMessageStart": "Ask your account administrator to link you to ",
  "noStationPermissionErrorMessageEnd": "from the station settings.",
  "noClosedShiftsYet.payments": "Here you will know the summary of the payment methods used by your clients in closed shifts.",
  "noClosedShiftsYet.shifts": "In this space you will see the behavior of your income and expenses included in the closed shifts.",
  "notes": "Notes",
  "notesHelpTooltip": "Visible in the document printout",
  "notConfiguredPaymentMethod": "Payment method not configured",
  "notConfiguredPaymentMethodMessage": "If you want to configure it you must go",
  "notInvoiceYet": "Create your first sale in seconds.",
  "notifications": "Notifications",
  "notificationTomorrowForFE": "You will be notificated tomorrow.☝️",
  "notificationTomorrowForFE.description": "Keep in mind that it's necessary accept newest terms and conditions in order to make electronic invoices.",
  "notPaymentYet": "Create your first cash transaction to record an inflow or outflow of money.",
  "notQRPaymentYet": "You have not created your first payment, create it here",
  "notRefundYet": "Create in seconds the first return a customer makes to you.",
  "notResponsibleForConsumption": "Not responsible for consumption",
  "notResponsibleForConsumptionSimplifiedRegimeOfTheINC": "Not responsible for consumption (simplified regime of the INC)",
  "notResponsibleForVAT": "Not responsible for VAT",
  "notResponsibleForVATSimplifiedRegime": "Not responsible for VAT (simplified regime)",
  "notSelected": "Not selected",
  "noZeroAllowed.title": "Indicate the amount to adjust",
  "noZeroAllowed.subtitle": "Note that the values in the <span class='font-weight-bold'>“Amount”</span> column must be different from zero.",
  "number": "Number",
  "numberOfInvoices": "number of invoices",
  "numeration": "Numeration",
  "numerationExpired": "Your numbering is expired 📅",
  "numerationExpired.body": "Choose another numbering or set up a new one to continue invoicing.",
  "numerationExpired.button": "How to set up another",
  "numerationExpired.notification.title": "The date of your numbering has expired! 📅",
  "numerationExpired.notification.text": "Keep in mind that the numbering {} is already expired and from July 17 you will not be able to use it when selling.",
  "numerationExpired.tooltip": "The numbering is expired",
  "numerationMaxNumberReached": "Your documents are over",
  "numerationMaxNumberReached.body": "Choose another numbering or set up a new one to continue invoicing.",
  "numerationMaxNumberReached.button": "How to set up another",
  "numerationMaxNumberReached.notification.title": "The consecutive of your numbering is over! 🚨",
  "numerationMaxNumberReached.notification.text": "Keep in mind that the numbering {} is sold out and from July 17 you will not be able to use it when selling.",
  "numerationMaxNumberReached.tooltip": "Numbering ran out of documents",
  "numerations": "Numerations",
  "numerationsSetting": "Numbering settings",
  "numerationsSettingTooltip": "Manage the numbering used for invoicing in your business from “Alegra Management”.",
  "objective": "goal",
  "obligatoryItemsDescription1": "The description of your items is required.",
  "obligatoryItemsDescription2": "Add it to issue your electronic documents at SUNAT, know how to do it",
  "observations": "Observations",
  "of": "of",
  "ofFreeTrialToDiscoverHowEasyItIsToRunYourBusiness": "of free trial to find out how easy it is to run your business.",
  "offlineNotificationTitle": "You are using Alegra POS without internet!",
  "offlineNotification":
    "You can continue invoicing and your sales will be saved when you get back online.",
  "onboardingEndSubtitle":
    "It's your turn, discover that having control of your sales and managing your business cash is now possible with Alegra POS! 🙌",
  "onboardingEndTitle": "Sell easily and quickly at your point of sale!",
  "onboardingWelcomeSubtitle": "Discover in the following tour how easy it is to manage your point of sale invoices and start giving your business superpowers.",
  "onboardingWelcomeSubtitleClothingStore": "Discover in the following tour how easy it is to manage your store's sales 👕🕶 and start giving your business superpowers.",
  "onboardingWelcomeTitle": "Welcome to Alegra POS!",
  "onboardingWizardSubtitleStep0": "It is the first step to personalize your point of sale",
  "onboardingWizardSubtitleStep1": "Hit play and discover how easy it is to manage your business with Alegra POS",
  "onboardingWizardSubtitleStep1ClothingStore": "Press play and discover how easy it is to <b>manage your clothing store</b> from today",
  "onboardingWizardTitleStep0": "Configure your business data!",
  "onboardingWizardTitleStep0ClothingStore": "Add the details of your clothing store!",
  "onboardingWizardTitleStep1": "Take control of your point of sale!",
  "onboarding.accounting.button": "Try 15 days for free",
  "onboarding.accounting.header": "Give your company superpowers!",
  "onboarding.accounting.message.1": "Get a",
  "onboarding.accounting.message.2": "complete view of all your business information",
  "onboarding.accounting.message.3": "with Alegra Accounting, manage your purchases, sales and inventory. You can also generate intelligent reports.",
  "onboarding.payroll.button": "Try 15 days for free",
  "onboarding.payroll.header": "Work out your ePayroll and comply with your Tax Revenue Service",
  "onboarding.payroll.message.1": "Now with Alegra Payroll, you can",
  "onboarding.payroll.message.2": "issue in record time",
  "onboarding.payroll.message.3": "the payment vouchers for your employees complying with your country’s Tax Revenue Service in an easy way.",
  "onboarding.readonly.button": "CHOOSE PLAN",
  "onboarding.readonly.header": "Keep selling fast and easy! 😎",
  "onboarding.readonly.message.1": "You already enjoyed your free trial,",
  "onboarding.readonly.message.2": "choose your plan now",
  "onboarding.readonly.message.3": "and continue to be in control of your points of sale.",
  "onboarding.shop.button": "Try 15 days for free",
  "onboarding.shop.header": "Your business always online with Alegra Store!",
  "onboarding.shop.message.1": "Start selling on the internet, advertise your products or services, and have your business",
  "onboarding.shop.message.2": "a single click away,",
  "onboarding.shop.message.3": "getting more clients with our new sales channel.",
  "onboarding.suspendedPlan.button": "Activate my account",
  "onboarding.suspendedPlan.header": "This feature is inactive",
  "onboarding.suspendedPlan.message":
    "We have had problems with the payment of your plan and your account has been suspended. You can activate it by making your payment and if you have any concerns, write to us by email",
  "onboarding.video.url": "https://www.youtube.com/embed/sFZpQMY6P3Q",
  "onboarding.video.id": "sFZpQMY6P3Q",
  "onlyInvoicingTooltip": "To enjoy this functionality, purchase an all-inclusive plan",
  "onlyNumericValuesAreSupported": "Only numeric values are supported",
  "open": "Open",
  "openShift": "Open shift",
  "openShiftError": "Shift opening error",
  "openShiftMessage": "With the shifts you will have better cash management at your point of sale",
  "openShiftMoreMessage": "Learn more about shifts",
  "operationType": "Type of operation",
  "option": "Option",
  "options": "Options",
  "orRestartYourBrowser": "or restart your browser",
  "other": "other",
  "otherMethods": "Other methods",
  "others": "Others",
  "outAmount": "Retired value",
  "outPayment": "Retirement",
  "outstandingInvoiceAmount": "Amount pending",
  "outType": "Retirement",
  "page": "Page",
  "paid": "Paid out",
  "paperSalesInvoice": "Paper bill of sale",
  "payInvoice": "Pay invoice",
  "payAnyDocument": "Pay sale",
  "paymentDate": "Payment date",
  "paymentDateDay": "October 1, 2021",
  "paymentDeclined8.title": "Update your payment details",
  "paymentDeclined8.text": "We have detected a problem with your payment method when charging your subscription, <b>would you like to add a new payment method?</b>",
  "paymentDeclined8.init": "If you need help, you can ",
  "paymentDeclined8.link": "contact support.",
  "paymentDeclined8.action": "Add payment method",
  "paymentDeclined12.title": "Update your payment details",
  "paymentDeclined12.text": "We have detected a problem with your payment method when charging your subscription, <b>would you like to add a new payment method?</b>",
  "paymentDeclined12.init": "If you need help, you can ",
  "paymentDeclined12.link": "contact support.",
  "paymentDeclined12.action": "Add payment method now",
  "paymentForm": "Payment form",
  "paymentForm.Cash": "Cash",
  "paymentFrequency": "Payment frequency",
  "paymentMethod": "Payment method",
  "paymentMethodHelp": "In Alegra POS you can create cash invoices, know how to generate your credit invoices in Alegra Accounting",
  "paymentNotVerified": "Unverified payment",
  "paymentOrder": "Pay order",
  "paymentsClientHelp": "Check if the option is linked to a customer",
  "paymentsLoadError": "Error loading station cash operations",
  "paymentTerm": "Payment deadline",
  "paymentTermCreatedSuccessfully": "Payment term created successfully",
  "paymentType": "Payment type",
  "paymentTypes": "Payment types",
  "payYourPlan": "Pay your plan",
  "payYourPlanIndications1": "Keep enjoying your demo, on October 1 we will send you the steps to",
  "payYourPlanIndications2": "configure your payment method",
  "payYourPlanIndications3": "and activate your plan.",
  "pending": "Earring",
  "pendingInvoices": "Pending sales",
  "pendingInvoiceSettingsTutorial": "Create several invoices at the same time to manage, for example, table control.",
  "pendingInvoicesPrefix": "Pending sales prefix",
  "pendingPaymentMethod": "Pending payment method",
  "pendingPaymentMethodMessage": "This may take a while",
  "pendingSales": "Pending sales",
  "percentage": "Percentage",
  "periodDifference": "Difference",
  "periodExpense": "Expenses for the period",
  "periodIncome": "Income for the period",
  "periods": "Periods",
  "personalCareAestheticsHealth": "Personal care / Aesthetics / Health",
  "personTypeByNacionality": "Type of person according to nationality",
  "phone": "Telephone",
  "physicalPerson": "Physical person",
  "planConsumption": "Plan consumption",
  "planIncomeFeature": "Income up to {}{} {} per month",
  "planInvoicesFeature": "{} invoices per month",
  "planInvoiceUnlimitedFeature": "Unlimited invoices per month",
  "planLimitReached": "You exceeded the limits of your {}",
  "planLimitReachedTitle": "Your business continues to grow! 🙌",
  "planLimitReachedSubtitle":
    "Change to a plan tailored to your needs to continue selling easily and quickly at your point of sale with greater benefits.",
  "planLimitReachedDescription1": "More monthly income",
  "planLimitReachedDescription2": "Greater number of users",
  "planLimitReachedDescription3": "More warehouses for your inventory",
  "planLimitReachedFooter": "Do you have doubts about which one to choose? <span class='font-weight-bold'>We help you</span> 👉'",
  "planName.pyme-pos": "Plan Pyme",
  "planName.pro-pos": "Plan Pro",
  "planName.plus-pos": "Plan Plus",
  "planName.emprendedor-pos": "Plan Starter",
  "planName.corporativo-pos": "Plan Corporative",
  "pos": "POS",
  "posDocument": "POS Document",
  "positiveBalance": "Positive balance",
  "POSIntroductoryCourse": "POS introductory course",
  "POSSetting": "POS functionalities",
  "posSettingsSubtitles": "Here you can configure the data directly involved with POS",
  "POSSettingTutorial": "Activate the functionalities that you will use in all your stations",
  "POSStations": "POS stations",
  "POSStationsFeature": "{} Points of sale",
  "POSStationsFeatureUnlimited": "Unlimited points of sale",
  "POSTutorial": "POS tutorial",
  "preInvoice": "Prefecture",
  "prepareFEServiceDown": "Get ready for DIAN maintenance!",
  "prepareFEServiceDown.description": "Today {} of {}, between {} and {}, the Electronic Billing System will be undergoing maintenance.",
  "prepareNow": "Prepareme now",
  "presumptiveRent": "Presumed income",
  "previous": "Previous",
  "price": "Price",
  "priceBeforeTaxes": "Base price",
  "priceList": "Price list",
  "priceListActivatedSucessfully": "Price list activated successfully",
  "priceListCreatedSuccessfully": "You can now associate your price list. 🎊",
  "priceListCreatedSuccessfullyMessage": "Your list {} is now available for you to associate with your products.",
  "priceListDeactivatedSuccessfully": "Price list deactivated successfully",
  "priceListDeletedSuccessfully": "Price list deleted successfully",
  "priceListDeletedError": "Error deleting the price list",
  "priceListStatusError": "Error changing price list status",
  "priceListUpdatedSuccessfully": "Price list updated successfully",
  "priceListType.percentage": "Percentage",
  "priceListType.amount": "Amount",
  "priceLists": "Price lists",
  "priceListsHelp": "Use price lists to change the price of your products quickly",
  "priceListsHelpTooltip": "Price lists allow you to define special prices for customers or groups of products and services, configure them from your point of sale!",
  "priceListsKnowMore": "Learn more",
  "priceListsLoadError": "An error occurred loading the price lists.",
  "priceListsNoDataText": "Create your first price list in the blink of an eye. 👀",
  "priceListsSubtitle": "Assign various prices to your products with a fixed value or a percentage discount on the base price.",
  "print": "Print",
  "printAlign": "Header alignment",
  "printAlignHelp": "Choose how your business data will be displayed.",
  "printDescription": "Include a description of your products in your invoices",
  "printDescriptionHelp": "This option will show the description of the products in the print",
  "printedRepresentationOfElectronicInvoice": "Printed representation of the electronic invoice",
  "printedRepresentationOfElectronicAdjustNote": "Printed representation of the electronic adjust note of electronic equivalent document",
  "printedRepresentationOfElectronicCreditNote": "Printed representation of the electronic credit note",
  "printFormat": "Print format",
  "printFormatHelp": "Select the size and margins of your template in millimeters (mm)",
  "printItemFullLine": "Group the product data in a column",
  "printItemFullLineHelp": "Check to display the name, quantity, and price in a single column.",
  "printLeftMargin": "Left margin",
  "printLeftMarginHelp": "Set the margin in mm",
  "printLogo": "Add your logo in the printing of your invoices",
  "printOnInvoice": "Print on invoices",
  "printCustomPhrase": "Custom Phrase:",
  "printCustomPhrasePlaceholder": "Try to keep it to a maximum of 200 characters",
  "printImageOrQr": "Image or QR",
  "printPersonalizedInvoices": "Invoice customization",
  "printPersonalizedInvoicesHelp": "Give your touch to the final part of your invoices with a phrase, image, or both.",
  "printRightMargin": "Right margin",
  "printRightMarginHelp": "Set the margin in mm",
  "printSize": "print size",
  "printTemplate": "Print template",
  "printTemplateField": "Printing type",
  "printTemplateHelp": "Select the template for printing your invoices.",
  "printTemplateSettingInfo": "Configure how your business invoices will look",
  "printUnitPrice": "Show unit price of your products on your invoices",
  "printUnitPriceHelp": "This option will show the unit price of the products in the print",
  "printMeasurement": "Include the unit of measure in your tickets",
  "printMeasurementHelp": "Highlight the unit of measurement of your products and services.",
  "printLines": "Show total lines and products on your invoices",
  "printLinesHelp": "Include the number of lines on your bill and the total number of sold products.",
  "product": "Product",
  "productCodeAssignedByTheIndustry": "Industry assigned product code",
  "productDistributionHelp.init": "Learn how to",
  "productDistributionHelp.mid": " distribute this product",
  "productDistributionHelp.end": " in different warehouses.",
  "productKey": "Product code",
  "productKeyHelp": "It is a mandatory field if you generate electronic invoices, you can know it ",
  "productName": "Product name",
  "productsToRefund": "Items to refund",
  "productWithoutVariants": "Product without variants",
  "productWithVariants": "Product with variants",
  "productWithVariantsHelp": "Indicate if this product has variants such as color, size or other quality.",
  "province": "Province",
  "pstRegime": "PST regimen",
  "qrPayments": "QR payments",
  "QRPaymentsLoadError": "Error loading payments from external providers",
  "quantity": "Quantity",
  "quantityExceededInInventory": "Exceeds quantity in inventory",
  "quater": "Quarter",
  "readAndAccept": "Read and accept",
  "realEstate": "Real estate",
  "reason": "reason",
  "reasonRefund": "Reason",
  "receiptNumbering": "Cash receipt numbering",
  "recommendedPlan": "Recommended ⭐",
  "reference": "Reference",
  "referenceHelp": "Unique code to identify your products, example CAS002",
  "referenceMaximumCharactersForEInvoice": "The reference can have a maximum of 20 characters to choose it in an electronic document.",
  "refund.electronicCreditNote": "Electronic credit note",
  "refund.creditNote": "Credit note",
  "refund.creditNoteStamped": "The credit note was successfully issued",
  'redund.adjustmentNoteStamped': "The adjustment note was successfully issued",
  "refund.adjustmentNote": "Adjustment note",
  "refund.adjustemntNoteDocumentPos": "Electronic equivalent document adjustment note",
  "refundAccountWarning": "Only the values associated with the Cash POS bank account will be deducted from the cash value of your shift closing.",
  "refundErrorTotalAmount": "The amount associated with the sales must be equal to the total amount of the credit note",
  "refundCash": "Refund of money",
  "refundCombinedMethodDescription": "You can use refund of money and invoice credit",
  "refundCreatedSuccessfully": "Return created successfully",
  "refundCreditToInvoiceMethodDescription": "It will decrease the value payable on your client's invoice",
  "refundNumbering": "Return numbering",
  "refundPositiveBalanceMethodDescription": "It will remain as an advance for a new invoice",
  "refundRefundCashMethodDescription": "Cash will be deducted from the account you choose",
  "refundAmount": "Amount to be refunded",
  "refundCreated": "Refund created 🎉",
  "refundCreatedMessage.adjustNote": "Your adjustment note has been successfully recorded.",
  "refundCreatedMessage.creditNote": "Your credit note has been successfully recorded.",
  "refundItems": "Refunded items",
  "refundItemInactive": "inactive, Activate it again in order to be able to refund it.",
  "refunds": "Returns",
  "refundSettingsError": "Failed to change settings",
  "refundsLoadError": "Error loading returns from station",
  "refundTotalExceedsBalance": "The total to be refunded must be less than or equal to the outstanding amount",
  "refundType": "Credit note type",
  "regime": "Regime",
  "regimeOfAgriculturalLivestockSilvicultureFishingActivities": "Regime of Agricultural, Livestock, Forestry and Fishing Activities",
  "regimeOfLegalEntitiesForNonProfitPurposes": "Regime of Legal Persons with Non-Profit Purposes",
  "regimeOfTheCoordinates": "Coordinated Regime",
  "rejected": "rejected",
  "reloadPage": "reload page",
  "rememberConfigureCAEA": "Remember to configure your CAEA numeration! 👉",
  "rememberConfigureCAEA.description":
    "If you need to stamp electronic invoices in your shop, you must request and configure a contigency CAEA numeration.",
  "rememberCloseTheShift": "Remember to close the shift or deactivate the option to control shifts to have a better experience.",
  "rename": "Rename",
  "renamePendingInvoice": "Rename pending invoice",
  "renamePendingInvoiceHelp": "To change the general prefix you have to go to",
  "required": "Required",
  "requiredCustomFields": "Additional required fields",
  "requiredCustomFieldsHelp": "Complete your required fields before creating your product",
  "requiredHelp.customField": "If you activate this option, you must always define a value for this field in your products.",
  "requiredField": "This field is required",
  "requiredFields": "Required fields",
  "requiredFieldsMissing": "There are some required fields missing",
  "resolution": "Resolution",
  "responsibility": "Responsibility",
  "responsibleForVAT": "Responsible for VAT",
  "responsibleForVATCommonRegime": "Responsible for VAT (common system)",
  "result": "result",
  "results": "results",
  "resultsPerPage": "Results per page",
  "retailTrade": "Retail trade",
  "retry": "Retry",
  "reversed": "Reversed",
  "reviewPending": "Check pending",
  "reviewRequirements": "Review requirements",
  "rucNotAvailable.summary": "To issue an electronic ticket, your client must have a type of identification other than RUC.",
  "rucNotAvailable.summary": "To issue an electronic ticket, your client must have a type of identification other than RUC.",
  "identificationNotAvailable.new.summary": "You must choose a ticket numbering because the client does not have 'RUC' as identification type.",
  "RNCEnteredDoesNotExistOrIsInvalid": "The RNC entered does not exist or contains invalid data, verify the information and redo the query or continue adding the data manually.",
  "RUCEnteredDoesNotExistOrIsInvalid": "The RUC entered does not exist or contains invalid data, verify the information and redo the query or continue adding the data manually.",
  "runtimeErrorMessage": "Please, try again",
  "sale": "Sale",
  "saleCondition": "Condition",
  "salesHistory": "Sale history",
  "salesHistoryLink": "Sales history →",
  "salePrice": "Sale price",
  "salePriceHelp": "Product price before tax",
  "sales": "Sales",
  "saleTicket": "Ticket",
  "saleTicketSaved": "Ticket saved",
  "saleTickets": "POS equivalent documents",
  "saleTickets.subtitle": "Create equivalent documents to record and support the sales of your business.",
  "saleTicketsFieldHelp": "Create documents equivalent to the electronic invoice for points of sale.",
  "salesNumbering": "sales numbering",
  "saveAndCreateNew": "Save and create new",
  "saveAndCreateNewAlt": "Save and create new",
  "save": "Save",
  "saveChanges": "Save changes",
  "savedChangesOnSellPreferences": "Saved changes to your sales preferences",
  "saveInventoryAdjustment": "Save adjustment",
  "savePayment": "Save payment",
  "savingChanges": "Saving your changes...",
  "scanQRCode": "Scan QR code",
  "scanTheCode": "Scan this code with your app",
  "search": "Search",
  "searchActivity": "Search activity",
  "searchItemByBarcode": "Barcode",
  "searchItemByText": "Search Products",
  "seeHelp": "See help",
  "secondName": "Second name",
  "secondNoIvaDayItsComing": "The second Day without IVA of 2022 is coming! 🚀",
  "secondNoIvaDayItsComing.description": "Find out everything you need to keep in mind this June 17 to increase your sales during the second IVA-Free Day of the year.",
  "sector": "Sector",
  "security": "Security",
  "securityCode": "Security code",
  "seeContract": "See contract",
  "seeMore": "see more",
  "seePrices": "Show me prices",
  "select": "Choose",
  "selectACause": "Select a cause",
  "selectAClient": "Select a client",
  "selectAItemAtLeast": "Select a item at least",
  "selectAStation": "Select a station",
  "selectAStationHeadline": "Select the station to execute your operations",
  "selectProduct": "Select product",
  "selectProductHelp": "Add here the products of your combo",
  "selectProducts": "Select the products",
  "selectProductsToCreateYourInvoice": "Here you will see the products you choose in your next sale",
  "selectProductsToCreateYourRefund": "Select at least one product to create your return",
  "selectTheIdentificationType": "Select the type of identification",
  "selected": "selected",
  "selectedPlural": "selected",
  "sell": "To sell",
  "sellDetails": "Details in sales",
  "sellDetailsInfo": "manage the data related to the registration of your sales",
  "seller": "Seller",
  "sellerActivated": "Successfully activated seller",
  "sellerCreated": "Successfully created seller",
  "sellerCreatedSuccessfully": "Very well.",
  "sellerCreatedSuccessfullyMessage": "You can now associate {} as a seller on your invoices.",
  "sellerDeactivated": "Vendor disabled successfully",
  "sellerDeleted": "Seller successfully removed",
  "sellerDeletedError": "Deleting seller error",
  "sellerProductCode": "Vendor Product Code",
  "sellers": "Vendors",
  "sellersLoadError": "An error occurred loading vendors",
  "sellersSettingInfo": "Manage the information of your sales team members",
  "sellersSettingSubtitle": "Create and manage the members of your sales team.",
  "sellerStatusError": "Error changing vendor status",
  "sellerUpdated": "Seller updated successfully",
  "sellSaved": "Saved sale",
  "sellPreferences": "Sell preferences",
  "sellPreferences.desc": "Customize the currency and additional charges you will include in your invoices.",
  "sellPreferences.tip.LinkDesc": "Read article about tips",
  "semiIntegrated": "Semi integrated",
  "send": "Send",
  "sendByMail": "Send by e-mail",
  "sendEmail": "Send an e-mail",
  "sendNequiNotificationToClient": "Send a payment notification to your client's Nequi.",
  "sendPaymentRequest": "Send payment request",
  "sendRequestToApp": "Send request to the app",
  "service": "Service",
  "serviceKey": "Service code",
  "serviceName": "Service name",
  "settings": "Settings",
  "setStationWarehouse": "Set a warehouse for your terminal",
  "setStationWarehouseHelp": "It seems that the warehouse associated to the terminal was deleted or disabled, Select one to continue selling.",
  "shift.tipsCash": "Ti in cash",
  "shift.tipsOther": "Tip in other",
  "shiftClosed": "Shift closed",
  "shiftClosedSuccessfully": "Boom!",
  "shiftClosedSuccessfullyMessage": "Your shift is closed and you are already having control of the cash at your point of sale.",
  "shiftClosingTitle": "Known your cash movements in your current shift.",
  "shiftIsOpenToMuchTime":
    "You have an open shift for more than a week. We recommend you close it to control your income or deactivate this option from your business settings.",
  "shiftNumber": "Shift number",
  "shiftOpen": "Open shift",
  "shiftOpenSuccessfully": "Shift open successfully",
  "shifts": "Shifts",
  "shiftSetting": "Shift configuration",
  "shiftSettingTutorial": "Choose the opening and closing bank of shifts for this station.",
  "shiftsField": "Cash shifts",
  "shiftsField.subtitle": "Have control of your money using the opening and closing of the box",
  "shiftsFieldHelp": "Take control of your money using the opening and closing of the cash shift",
  "shiftsLoadError": "Error loading shift history from station",
  "shiftsStatisticsHelpText": "Know the detail of your closed shifts in the period of time you choose.",
  "shiftsStatisticsInfo": "Know the information of your income and expenses in closed shifts.",
  "shiftStatistics": "Shift reports",
  "showDescription": "Show description",
  "showing": "Showing",
  "showInfo": "Show info",
  "showingPage": "Showing page",
  "showLogo": "Show logo",
  "showShortcuts": "Show shortcuts",
  "showUnitPrice": "Show prices",
  "sign": "Sign",
  "simple": "Simple",
  "simpleEstimationRegime": "Simple estimation regime",
  "simpleTaxationRegime": "Simple tax regime",
  "simplifiedRegime": "Simplified regimen",
  "simplifiedTaxation": "Simplified taxation",
  "skip": "Skip",
  "smartDataUpdate": "Smart data update ✨",
  "smartDataUpdate.description": "Starting in May, <b>your company name in Alegra must match your RUT</b> acording to DIAN. We update it for you from now on so that your business is always up to date.",
  "socialRazon": "Business name",
  "solutions": "Solutions",
  "solutions.description": "Solutions that give superpowers to your business",
  "solutions.managment": "Manage solutions",
  "specializedServices": "Specialized services",
  "specialRegime": "Special regime",
  "specialTaxationRegimeInvoice": "Invoice of Special Tax Regime",
  "specialTaxationRegimes": "Special tax regimes",
  "stamped": "Stamped",
  "stamped03": "Stamped-03",
  "stampedWithObservations": "Stamped with observations",
  "start": "Start",
  "startDate": "Start date",
  "startPreparing": "Start preparing",
  "startTour": "Start tour",
  "state": "State",
  "station": "station",
  "stationActivated": "station activated successfully",
  "stationCreatedSuccessfully": "Ready!",
  "stationCreatedSuccessfullyMessage": "Terminal X is now available for you to manage the sales of your business.",
  "stationDeactivated": "station disabled successfully",
  "stationDeleted": "station removed successfully",
  "stationDeletedError": "station deleting error",
  "stationName": "Name of your station",
  "stationRefundNumbering": "numbering for customer returns",
  "stations": "stations",
  "stationSetting": "station configuration",
  "stationSettingInfo": "Manage your points of service and control the sales of each one of them",
  "stationSettingSubtitle": "Configure your station data and the necessary information for invoicing.",
  "stationSettingTitle": "Current station",
  "stationsForYourPointOfSale": "Terminals for your points of sale",
  "stationsLoadError": "An error occurred loading the stations",
  "stationsSettingSubtitle": "Create and manage the stations that you use to manage your points of sale.",
  "stationsSettingTitle": "Gestiona tus terminales POS",
  "stationStatusError": "Error changing station state",
  "stationUpdated": "Updated station",
  "stationWarehouseDeletedError": "You can only delete warehouses that are not associated with a terminal.",
  "stationWarehouseStatusError": "You can only disable warehouses that are not associated with a terminal.",
  "statistics": "Reports",
  "status": "Condition",
  "stopMakeVisible": "Stop making visible",
  "stopShowInfo": "Stop showing info",
  "street": "Street",
  "subscriptionHeader": "Quick and easy invoices now!",
  "subscriptionIndications": "With Alegra POS you can sell in seconds and make your business grow. Find out below the details of your subscription:",
  "subscriptionLoadingMessage": "Wait a moment while your plan information loads. ⌛",
  "subscriptionStatus": "Subscription status",
  "subtotal": "Subtotal",
  "success": "Success",
  "successful": "Successful",
  "successfulPayment": "Successful payment!",
  "sumary": "Sumary",
  "sunatCode": "SUNAT code",
  "sync": "Sync up",
  "syncError": "Sync error",
  "syncErrorSubtitle": "When there are errors in the synchronization you must validate the permissions or connection problems",
  "synchronizeData": "Synchronize data",
  "syncing": "Synchronizing",
  "tariffHeading": "Tariff item",
  "tariffHeadingHelp": "Exclusive use for exporters in the sale of merchandise",
  "tax": "Tax",
  "taxCreditInvoice": "Tax Credit Invoice",
  "taxedSubtotal": "Taxed Subtotal",
  "taxName": "Tax name",
  "taxes": "Taxes",
  "taxesHelp": "If your product has several taxes, you can select them by clicking on the name of each one in the drop-down list.",
  "taxLiability": "Tax liability",
  "taxesSettingTooltip": "Configure the taxes you need for invoicing at your point of sale “Alegra Management”.",
  "technologyTelecommunications": "Technology / Telecommunications",
  "tellUsMoreAboutYourBusiness": "Tell us more about your business so that Alegra works especially for you",
  "termsConditions": "Terms and Conditions",
  "termsConditionsForElectronicInvoices": "Terms and conditions for electronic billing",
  "termsConditionsForElectronicInvoices.description": "The new terms and conditions will allow you to <b>continue using Alegra's digital certificate</b> to issue your electronic receipts.",
  "theAmountCannotBeGreaterThanRefundTotal": "The amount must be less than or equal to the total of the refund",
  "theCombinedAmountCannotBeGreaterThanRefundTotal": "The combined amount must be less than or equal to the total of the refund",
  "theDIMEXIsANumericalValue": "The DIMEX is a numeric value, without leading zeros and without hyphens.",
  "theLegalIDMustBeWithoutHyphens": "The legal identity card must be without hyphens.",
  "theMaximumSizeForThisFieldIs": "The maximum field size is",
  "theMinimumSizeForThisFieldIs": "The minimum field size is",
  "theNITEMustBeWithoutHyphens": "The NITE must be without hyphens.",
  "thereWasAnErrorLoadingShiftValues": "There was an error loading the shift values.",
  "theValueOfPaymentsShouldBeGreaterThanZero": "The value of payments must be greater than zero",
  "thisFieldIsRequired": "This field is required",
  "ticket": "Ticket",
  "ticketsPerMonth": "Tickets per month",
  "TipSuggested": "Tip suggested",
  "tip": "Tip",
  "tips": "Tips",
  "tips.add": "Add tips",
  "tips.subtitle": "Include the charge for tip or service in your sales documents.",
  "tips.defaultSuggested": "Default suggested",
  "tips.defaultSuggestedDesc": "Automatically add the tip to each sale, and you can edit or remove it when selling.",
  "tips.suggestedPercentageLabel": "suggested percentage",
  "tips.suggestedPercentageHelp": "It is calculated based on the sale value before taxes, and you can modify it when selling.",
  "tips.accountLabel": "Account for tips",
  "tips.accountHelp": "Choose the accounting account for recording the collected tips.",
  "tips.modalTitle": "Add tips to your sales! 💰",
  "tips.modalTitleSector": "Add tips to your sales! 💰",
  "tips.modalDescription": "You can activate this feature to include tip collection when creating your sales invoices.",
  "tips.modalDescriptionSector": "Configure your tip percentage and activate the option to suggest it automatically on every sale.",
  "tips.activateTips": "Go to activate tips",
  "tips.activateTipsSector": "Configure tips",
  "to": "to",
  "today": "Today",
  "todayIsSecondNoIvaDay": "It's today, today is the second Day without IVA! 🙌",
  "todayIsSecondNoIvaDay.description": "Check that you have everything ready to increase your sales on this big day and continue giving superpowers to your business.",
  "toPay": "To pay",
  "total": "Total",
  "totalCost": "Total cost",
  "totalItems": "Total products",
  "totalOfMovements": "Total of movements",
  "totalOfShiftMovements": "Total of shift's movements",
  "totalQuantity": "Total Quantity",
  "totalReceived": "Total received",
  "totalRows": "Total lines",
  "totalToRefund": "Total to refund",
  "totalSale": "Total sale",
  "totalToPay": "Total to pay",
  "tours.onboarding.content.1": "This option will allow you to create the products and services that you offer.",
  "tours.onboarding.content.1.clothing.stores": "This option will allow you to create the products that you sell in your store.",
  "tours.onboarding.content.2": "Add your clients or use the final consumer that is created by default.",
  "tours.onboarding.content.3": "You can select them with a click on the boxes that you see on the left side.",
  "tours.onboarding.content.4": "With this option you create your invoice, choose the payment method and print your receipt.",
  "tours.onboarding.title.1": "Your products with one click!",
  "tours.onboarding.title.1.clothing.stores": "Your products with one click! 👖👚",
  "tours.onboarding.title.2": "Create your contacts",
  "tours.onboarding.title.2.clothing.stores": "Create your clients 👥",
  "tours.onboarding.title.3": "Choose the products to sell",
  "tours.onboarding.title.3.clothing.stores": "👈 Choose the products to sell",
  "tours.onboarding.title.4": "Boom, your first invoice! 🎉",
  "tours.onboarding.title.4.clothing.stores": "Your invoice is ready! 🎉",
  "township": "Township",
  "tpaga": "Tpaga",
  "tradeName": "Trade name",
  "traditionalRegime": "Traditional regime",
  "transfer": "Transfer",
  "transferBank": "Transfer bank",
  "transferSales": "Sales by transfers",
  "transportLogistics": "Transport / Logistics",
  "tryNow": "Try it now!",
  "tutorial": "Tutorial",
  "type": "Type",
  "typeAdjustmentNote": "Type of adjustment note",
  "twoMonths": "2 Months",
  "ubigeoCode": "Ubigeo code",
  "understood": "Understood",
  "ungroupData": "Ungroup data",
  "unitCost": "Initial cost per unit",
  "unitCostHelp": "Indicate the average acquisition value of the initial units of this product.",
  "unitCostSimplified": "Initial cost",
  "units.centimeter": "Centimeter",
  "units.centimeterSquared": "Centimeter squared",
  "units.day": "Day",
  "units.foot": "Foot",
  "units.gallon": "Gallon",
  "units.gram": "Gram",
  "units.hour": "Hour",
  "units.inch": "Inch",
  "units.inchSquared": "Inch squared",
  "units.kilogram": "Kilogram",
  "units.kit": "Kit",
  "units.liter": "Liter",
  "units.meter": "Meter",
  "units.meterCubic": "Meter cubic",
  "units.meterSquared": "Meter squared",
  "units.mililiter": "Mililiter",
  "units.minute": "Minute",
  "units.notApplicable": "Not apply",
  "units.onza": "Onza",
  "units.piece": "Piece",
  "units.pound": "Pound",
  "units.second": "Second",
  "units.service": "Service",
  "units.thousand": "Thousand",
  "units.ton": "Ton",
  "units.unit": "Unit",
  "unlimited": "Unlimited",
  "unlimitedM": "Unlimited",
  "unlimitedSellers": "Unlimited sellers",
  "update": "update",
  "updateAndCreateNew": "Update and create new",
  "updateAndCreateNewAlt": "Update and create new",
  "updateCompanyError": "Error trying to update company data",
  "updateDefaultValue": "Please update the default value",
  "updateItemCategory": "Update category",
  "updateItemCategoryError": "There was an error changing the category data.",
  "updateItemVariantHelp": "The item variant can not be edited directly, edit the item parent.",
  "updatePriceList": "Update list",
  "updatePriceListError": "There was an error changing the price list data.",
  "updateInTermsAndConditions": "Update in terms and conditions",
  "updateInTermsAndConditions.description.1": "The <b>terms and conditions on electronic invoicing</b> have been updated. Before continuing, please read and sign the new agreement with the updates.",
  "updateInTermsAndConditions.description.2": "With Alegra you are always up to date with the DIAN!",
  "updateRequiredSettingsError": "Error saving values",
  "updateSellerError": "Failed to change seller details",
  "updateStationError": "Failed to change station data",
  "updateVariant": "Update variant",
  "updateVariantError": "There was an error changing the variant data.",
  "updateWarehouse": "Update warehouse",
  "updateWarehouseError": "Failed to change warehouse data",
  "urbanization": "Urbanization",
  "userAssociation": "user association",
  "userAssociationTutorial": "Choose the users who will have access to the use of this station",
  "useMyLogo": "Use my logo",
  "usePriceLists": "Use price list",
  "userNotAllowed.bank-accounts.view": "You do not have permissions to view the information of bank accounts",
  "userNotAllowed.categories.add": "You do not have permissions to add categories",
  "userNotAllowed.categories.edit": "You do not have permissions to edit categories",
  "userNotAllowed.categories.delete": "You do not have permissions to delete categories",
  "userNotAllowed.contacts.add": "You do not have permissions to add contacts",
  "userNotAllowed.contacts.view": "You do not have permission to view the contact details. Talk to your administrator to enable the permission so you can use the POS correctly.",
  "userNotAllowed.company.edit": "You do not have sufficient permissions to edit company information",
  "userNotAllowed.company.edit-invoice-template": "You do not have sufficient permissions to edit your company's print information",
  "userNotAllowed.cost-centers.view": "You do not have sufficient permissions to view details of cost centers",
  "userNotAllowed.credit-notes.add": "You do not have permissions to add returns",
  "userNotAllowed.credit-notes.view": "You do not have permission to view return details",
  "userNotAllowed.currencies.view": "You do not have permissions to see the coins",
  "userNotAllowed.custom-fields.add": "You do not have permissions to add additional fields",
  "userNotAllowed.custom-fields.edit": "You do not have permissions to edit additional fields",
  "userNotAllowed.custom-fields.delete": "You do not have permissions to delete additional fields",
  "userNotAllowed.inventory-adjustments.view": "You do not have permission to view inventory adjustments",
  "userNotAllowed.inventory-adjustments.add": "You do not have permission to add inventory adjustments",
  "userNotAllowed.inventory-adjustments.edit": "You do not have permission to edit inventory adjustments",
  "userNotAllowed.inventory-adjustments.delete": "You do not have permission to delete inventory adjustments",
  "userNotAllowed.invoices.edit-discount": "You do not have permission to edit the discount of the products",
  "userNotAllowed.invoices.edit-items-prices": "You do not have permission to edit the price of the products",
  "userNotAllowed.invoices.edit-items-prices-new": "You need the administrator to give you permission to edit the price.",
  "userNotAllowed.invoices.max-discount-allow": "The maximum discount percentage allowed for your user is",
  "userNotAllowed.invoices.view": "You do not have sufficient permissions to view invoice details",
  "userNotAllowed.items.add": "You do not have permissions to add products",
  "userNotAllowed.items.edit": "You do not have sufficient permissions to edit item information",
  "userNotAllowed.items.delete": "You do not have permissions to delete items",
  "userNotAllowed.payments.add": "You do not have permissions for the desired operation",
  "userNotAllowed.payments.view": "You do not have sufficient permissions to view payment details",
  "userNotAllowed.pos-cashier.close": "You do not have permissions to close shifts",
  "userNotAllowed.pos-cashier.open": "You do not have permissions to open shifts",
  "userNotAllowed.pos-cashier.view": "You do not have permission to view previous shift closings",
  "userNotAllowed.pos-station.edit": "You do not have sufficient permissions to edit the station information",
  "userNotAllowed.pos-station.index": "You do not have permission to see the stations",
  "userNotAllowed.pos-station.view": "You do not have permissions to see details of stations",
  "userNotAllowed.price-lists.add": "You do not have permissions to add price lists",
  "userNotAllowed.price-lists.edit": "You do not have permissions to edit price lists",
  "userNotAllowed.price-lists.delete": "You do not have permissions to delete price lists",
  "userNotAllowed.price-lists.view": "You do not have permissions to see the information of the price lists",
  "userNotAllowed.sellers.add": "You do not have permissions to add sellers",
  "userNotAllowed.sellers.delete": "You do not have permissions to delete sellers",
  "userNotAllowed.sellers.edit": "You do not have permission to edit sellers",
  "userNotAllowed.terms.view": "You do not have permissions to see the payment terms",
  "userNotAllowed.variant-attributes.add": "You do not have permissions to add variants",
  "userNotAllowed.variant-attributes.edit": "You do not have permissions to edit variants",
  "userNotAllowed.variant-attributes.delete": "You do not have permissions to delete variants",
  "userNotAllowed.warehouses.add": "You do not have permissions to add warehouses",
  "userNotAllowed.warehouses.delete": "You do not have permissions to delete warehouses",
  "userNotAllowed.warehouses.edit": "You do not have permission to edit warehouses",
  "users": "users",
  "validDateTo": "Valid up to",
  "value": "Value",
  "variant": "Variant",
  "variantActivatedSuccessfully": "Variant activated successfully",
  "variantCreatedSuccessfully": "It's ready. 👈",
  "variantCreatedSuccessfullyMessage": "Your variant {} is available to associate with your products.",
  "variantDeactivatedSuccessfully": "Variant deactivated successfully",
  "variantDeletedError": "Error deleting variant",
  "variantDeletedSuccesfully": "Variant removed successfully",
  "variantStatusError": "Error changing variant status",
  "variantUpdatedSuccessfully": "Variant updated successfully.",
  "variants": "Variants",
  "variantsInventoryInfo": "Configure attributes that define the characteristics of your products such as color, size or other quality.",
  "variantsNoDataText": "Create your first variant without getting up from the chair. 🎨",
  "variantsSubtitle": "Add attributes like size, color or other quality to categorize your products.",
  "videoIntroductionPOS": "Introductory video POS",
  "void": "Cancel",
  "voidInvoice": "Cancel exit",
  "voidInvoiceCause": "Reason for voiding this document",
  "voidInvoiceCauseHelp": "This information is necessary to generate your reports correctly.",
  "wantToParticipate": "I want to participate",
  "wantToParticipateOfSecondNoIvaDay": "Do you want to participate in the next day without IVA?☝️",
  "wantToParticipateOfSecondNoIvaDay.description": "Keep in mind that to offer this benefit you must have your electronic invoicing active and here we help you do it.",
  "warehouse": "Warehouse",
  "warehouses": "Warehouses",
  "warehouseActivated": "warehouse activated successfully",
  "warehouseChangeError": "You do not have the permissions to view products from other warehouses.",
  "warehouseChangeInfo": "Change the warehouse to view the products present in each one.",
  "warehouseCreatedSuccessfully": "How cool.",
  "warehouseCreatedSuccessfullyMessage": "Warehouse {} is now available for you to distribute your inventory.",
  "warehouseDeactivated": "warehouse deactivated successfully",
  "warehouseDeleted": "warehouse removed successfully",
  "warehouseDeletedError": "error deleting warehouse",
  "warehouseQuantity": "Quantity in Warehouse",
  "warehouseSetting": "Cellar configuration",
  "warehousesLoadError": "An error occurred loading the warehouses",
  "warehousesSettingSubtitle": "Manage the warehouses of your business",
  "warehousesInventorySubtitle": "Create warehouses to manage your inventory in different storage and distribution locations.",
  "waherouseModalSubtitle": "Create all storage and distribution points for your products.",
  "warehouseStatusError": "error changing warehouse status",
  "warehouseUpdated": "Warehouse updated successfully",
  "warehouse_name": "Warehouse",
  "website": "Website",
  "week": "Week",
  "welcomeToAlegraPOS": "Welcome to Alegra POS!",
  "welcomeToYourPOSSystem": "Welcome to your POS system!",
  "wellCovered": "Well covered",
  "whatYouDo": "What is your job",
  "without": "Without",
  "writeToConfirm": "Confirm by typing the word",
  "writeWhatYouDo": "Write what you do",
  "xmlDocument": "XML Document",
  "yearly": "Yearly",
  "yearlyPlanButton": "Yearly with 10% of discount",
  "yearlyTotal": "Yearly total",
  "yes": "Yes",
  "yesterday": "Yesterday",
  "youCanConfigureYourEconomicActivities": "¡Configure all your economic activities! ⚡",
  "youCanConfigureYourEconomicActivities.description": "Mark the one you use the most as preferred and change it if necessary when selling from your Point of Sale.",
  "youCanSeeIt": "can you see her",
  "youDontHaveCreatedItems": "You have not created your first product, create it here",
  "youCreated": "You created",
  "youDontHaveCreatedInventoryAdjustments.title": "Your first adjustment in seconds! ⚖",
  "youDontHaveCreatedInventoryAdjustments.subtitle": "Keep the quantity of your products up to date",
  "youDontHaveCreatedSellers": "You have not created your first seller, create it here.",
  "youDontHaveCreatedStations": "You have not created your first station, create it here",
  "youDontHaveCreatedWarehouses": "You have not created your first warehouse, create it here",
  "youHave": "You have",
  "youHaveA15DayFreeTrial": "You have a {} {} free trial to discover how easy it is to manage your business.",
  "youHaveOfflineData": "You have pending data to sync.",
  "youHavePendingInvoicesToEmit": "You have sales documents to stamp! 🚨",
  "youHavePendingInvoicesToEmit.description": "Learn how to identify the electronic documents you have “To stamp” and send them to the DIAN with a click.",
  "youMustChooseAMethod": "You must choose a return method",
  "youMustEditThisItem": "You must edit this item",
  "youMustSynchronizeBeforeCloseShift": "You must synchronize your information before you can close shift",
  "youMustIndicateTheNameOfYourClient": "You must indicate the name of your client",
  "youMustIncludeAtLeastOneLastName": "You must include at least one last name",
  "youNeedPermissionsToViewList": "You need permission to view the list",
  "youNeedToAddDiscountNature": "You need to indicate the nature of the discount",
  "youNeedToAddThePaymentValue": "You need to add the payment value",
  "youNeedToAskForPermissions.view-numerations": "Request permission from an administrator to view the numbering list.",
  "youNeedToConfigYourCompanyEmail": "First you need to set up your business email. Then retry",
  "yourBusinessData": "Your business data",
  "yourBusinessData.subtitle": "Update your business information that will appear on your invoices",
  "yourNumerationIsAboutToExpire": "Your numbering is about to expire! 📢",
  "yourNumerationIsAboutToExpire.description": "The numbering {} will expire soon. We recommend checking it and asking for a new one at the DIAN.",
  "yourNumerationIsAlmostOver": "Your consecutive numbering is almost over!⚠️",
  "yourNumerationIsAlmostOver.description": "You have a few documents available in your numbering {}. Ask for a new one to the DIAN and set it in seconds.",
  "yourNumerationIsExpired": "¡Your consecutive numbering is expired! 🚨",
  "yourNumerationIsExpired.description": "Your consecutive numbering {} is expired. Ask for a new one to the DIAN and configure it to continue selling.",
  "yourNumerationIsOver": "Your consecutive numbering is over! 🚨",
  "yourNumerationIsOver.description": "The numbering: {} is over. Ask for a new one at the DIAN to continue billing.",
  "youReceived": "You received {}",
  "youRegistered": "You registered",
  "yourInvoiceIsBeingAuthorizedByTheDGI": "Your invoice is being authorized by the DGI and in a moment you will be able to print it from the sales section.",
  "yourPOSSystem": "your POS system",
  "youShouldSelectANumeration": "You must select a numbering, ask an administrator for permission to view the list.",
  "zipCode": "Postal Code",
  "reload": "Recharge",
  "connectionIssueTitle": "Working without internet",
  "connectionIssueDescription": "You will be able to consult your data when you are connected.",
  "solutions.description.new": "More solutions for your business",
  "AlegraPayroll.new": "Payroll",
  "AlegraPayroll.description.new": "Settle and issue payments for your team.",
  "AlegraAccounting.new": "Management",
  "AlegraAccounting.description.new": "Reports, inventory control and more",
  "AlegraPOS.new": "Point of sale",
  "AlegraPOS.description.new": "Streamline your sales and control your cash",
  "AlegraShop.new": "Store",
  "AlegraShop.description.new": "Create your online store easily and quickly",
  "AlegraInvoicing.new": "Billing",
  "AlegraInvoicing.description.new": "Create your invoices in seconds",
  "deactivated": "Unable",
  "activated": "Activate",
  "electronicInvoicing": "Electronic invoice",
  "electronicPayrollHabilitation": "Find out how to enable yourself to stamp electronic invoices.",
  "avoidForNow": "Omit for now",
  "helpedArticle": "Read help article",
  "youCanNowMakeElectronicInvoices": "Enable yourself to stamp electronic Invoices! 💥",
  "howToEnableMe": "How to enable me?",
  "help": "Help",
  "HelpTooltipTotalSales": "Corresponds to the sales of the shift, not including tips or other cash movements.",
  "terminal_summary": "Terminal overview",
  "synchronization": "Synchronization",
  "new_design_tittle": "New design at your point of sale! 🖌️",
  "new_design_description": "Now your menu has a new look and color so you can more easily identify each section.",
  "maxLengthError": "Limit: {{maxCharacters}} characters",
  "importProducts.text": "Import products",
  "importProducts.tooltip": "We will take you to Alegra Management to upload new products.",
  "solutionModal.accounting.supTitle": "Clear accounts with",
  "solutionModal.accounting.buttonMainText": "Try 15 days free",
  "solutionModal.accounting.buttonSecondaryText": "More information",
  "solutionModal.accounting.description": "With Alegra Management, you can control your income and expenses with a click. You can also get administrative and sales reports in real time.",
  "solutionModal.accounting.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.store.supTitle": "Increase your sales with",
  "solutionModal.store.buttonMainText": "Try 15 days free",
  "solutionModal.store.buttonSecondaryText": "More information",
  "solutionModal.store.description": "Start selling online in seconds! With Alegra Store, you can create your first online store in just 4 clicks.",
  "solutionModal.store.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.pos.supTitle": "Manage your business with",
  "solutionModal.pos.buttonMainText": "Try 15 days free",
  "solutionModal.pos.buttonSecondaryText": "More information",
  "solutionModal.pos.description": "Invoice in seconds with the fastest Point of Sale System on the market. In addition, you can generate your sales and inventory reports in seconds. ⚡",
  "solutionModal.pos.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "maxReferenceCharactersExceeded": "Code of maximum {} characters that will help you identify your products.",
  "clientPortal": "Client portal",
  "userNotAllowed.edit.saleTicket": "You need the administrator to give you permission to edit tickets.",
  "userNotAllowed.edit.invoice": "You need the administrator to give you permission to edit invoices.",
  "simplifiedInvoice": "Simplified invoice",
  "ordinaryInvoice": "Ordinary invoice",
  "theDNIhasEightNumbersAndOneDigitVerificationCode": "Must have 8 numbers and a letter.",
  "mustHaveALetterAndSevenNumbersAndOneVerificationCode": "Must have a letter, seven numbers and a check digit.",
  "contact.ext.city": "city",
  "thePostalCodeMustHaveFiveNumbers": "Must have 5 numbers.",
  "writeThreeLettersToSearch": "Write at least 3 letters to search.",
  "noResultsTryAgain": "No results found, try again",
  "continue_selling": "Continue selling",
  "wifi_disabled": "⚠ Offline mode activated",
  "wifi_disabled_description": "You can continue selling and your invoices will be synchronized when you have internet.",
  "wifi_unavailable": "Selling offline",
  "wifi_enabled": "Point of sale available ⚡",
  "wifi_enabled_description": "You have internet connection and all features are available to grow your business.",
  "wifi_available": "Online",
  "connection_missed_title": "Offline mode activated",
  "connection_missed_description": "You can continue selling while you recover it. Please avoid refreshing the page to preserve your sales data so that they can be synchronized when you have internet.",
  "connection_restored_title": "Your connection is back 🙌",
  "connection_restored_description": "You can use all features as normal while we save the sales you made offline.",
  "offline_menu_blocked": "You will be able to access when you have internet",
  "offline_success_invoice_total": "Total {{total}}",
  "offline_success_invoice_title": "Sale pending synchronization",
  "offline_success_invoice_description": "The invoice was created and is pending synchronization, <b><u>do not reload the page</u></b> to continue selling.",
  "syncingSales": "We are saving the sales you made offline, in a moment everything will be ready.",
  "syncingSaleIntermittence": "We are saving the sales you made during the intermittency, in a moment everything will be ready.",
  "api_failure": "With intermittency",
  "api_failured": "⚠ Intermittent service",
  "api_failure_description": "You can continue to sell and your invoices will be synchronized when we stabilize service. We will keep you updated. 🤝",
  "api_failure_modal_title": "⚠ Intermittent service",
  "api_failure_modal_description": "We are experiencing technical failures in some functions and our team is working to fix it as soon as possible.",
  "api_intermittences_invoice_title": "It will be saved when we stabilize the service",
  "api_intermittences_invoice_description": "The invoice was created and is pending to be saved, we will save it for you when the intermittence is over.",
  "headerTopOfflineNotification": "<b>Do not reload the page</b> to continue selling offline and your sales will be synchronized when you have internet.",
  "cell.inactive": "Disabled",
  "cell.active": "Activated",
  "maxReferenceCharactersExceeded": "Code of maximum {} characters that will help you identify your products.",
  "confirm-delete": "This action cannot be undone. Are you sure you want to delete item?",
  "item-product": "product",
  "item-kit": "kit",
  "item-service": "service",
  "deactivatingItem": "Deactivating the",
  "activatingItem": "Activating the",
  "successfulItemDeactivated": "successfully deactivated",
  "successfulItemActivated": "successfully activated 🌟",
  "disabledEditItem": "You need the administrator to give you permission to edit products.",
  "disabledDisableItem": "You need the administrator to give you permission to deactivate products.",
  "disabledEnableItem": "You need the administrator to give you permission to activate products.",
  "disabledDeleteItem": "You need the administrator to give you permission to delete products.",
  "deletingItem": "Deleting el",
  "itemDeletedSuccess": "successfully deleted 🗑",
  "errorDeletingItem": "Error deleting the",
  "enablingProducts": "Activating {{total}} products...",
  "disablingProducts": "Deactivating {{total}} products...",
  "successfulProductsActivated": "Products successfully activated",
  "successfulProductsDeactivated": "Products successfully deactivated",
  "confirmDeleteProducts": "Confirm that you wish to permanently delete the <b>{{total}} selected products</b> from your account.",
  "deleteProducts": "Delete products",
  "mustSelectMultipleItems": "You must select more than 1 item to execute a mass action.",
  "successfulItemsDeleted": "Products successfully deleted 🗑",
  "massiveDeleteProductsErrorTitle": "We keep some products",
  "massiveDeleteProductsErrorDescription": "Check the detail of the products that have not been deleted because you have already used them in your account.",
  "motiveToKeepIt": "Reason for keeping it",
  "goToProducts": "Go to products",
  "incomes": "Incomes",
  "offlineStatusSyncSubtitle": "We recommend you to review the pending sales to be synchronized to avoid the loss of your information.",
  "contactSupportTitle": "We improve your support experience! 🔥",
  "contactSupportDescription": "Now you'll find all your support options gathered in one place and available 24/7. Explore your new support center!",
  "exploreNow": "Explore now",
  "duplicatedIdentificationTitle": "Duplicate ID",
  "duplicatedIdentificationDescription": "The ID <b>{{identification}}</b> is associated with <b>{{name}}</b>. Confirm if you want to create the duplicate client or sell to the existing contact.",
  "duplicatedIdentificationContinueButton": "Create duplicate client",
  "duplicatedIdentificationSellExistingContact": "Sell to existing contact",
  "globalInvoiceCreationDateTooltip": "Date on which SAT will stamp your invoice.",
  "globalInvoicePeriodicityPeriodTooltip": "Time range in which you created the tickets to be included in your invoice.",
  "globalInvoicePeriodicityMonthTooltip": "Month in which you created the tickets to be included in your invoice.",
  "globalInvoicePeriodicityYearTooltip": "Year in which you created the tickets, you can only choose the current or previous year.",
  "contactSupportTitleNewUsers": "Explore your support channels 🧑‍💻",
  "contactSupportDescriptionNewUsers": "Discover where to find help when you need it! Explore our support channels available 24/7.",
  "exploreNowNewUsers": "Explore now",
  "debitCardSaleModal": "Debit card",
  'onbPhoneMessageValidationError': 'Be sure to enter a {} digit phone number.',
  "posNews": "What´s New",
  "offileCreatingProductBlocked": "You need to have internet to create products.",
  "manageYourAccount": "Manage your account",
  "manageYourAccountDescription": "Find in one place the configurations that apply to all your Alegra solutions",
  "rolesAndUsers": "Roles and users",
  "legalSupports": "Legal supports",
  "enterYourClientsPhone": "Enter your client's phone number",
  "youNeedToIncludePhone": "You need to include a phone number to send",
  "shareInvoice.phone.error": "Enter your client's phone number",
  "shareInvoice.whatsapp.message.placeholder": "Message to send via WhatsApp",
  "shareInvoice.whatsapp.message.error": "Enter the message to send to your client",
  "shareInvoice.email.subject.error": "Enter a subject for your email",
  "contacts": "Contacts",
  "offileCreatingProductBlocked": "You need to have internet to create products.",
  "deleteContactsUnallow": "You need permission to delete contacts",
  "changeStatusContactsUnallow": "You need permission to change contacts status",
  "editContactsUnallow": "You need permission to edit contacts",
  "addContactsUnallow": "You need permission to create contacts",
  "createFirstClient": "Create your first client! 👥",
  "createFirstClientDescription": "Here you can manage all your point of sale contacts.",
  "noContacts": "No results 🔦",
  "noContactsDescription": "Try another name or adjust your search filters.",
  "youNeedPermissions": "You need authorization",
  "youNeedPermissionsDescription": "Your user has a limited profile. To access this section you must request access to an administrator user.",
  "contactsDescription": "Manage client information that you can associate with your sales documents.",
  "sendAlegraPortalLink": "Send Alegra Portal link",
  "loadingTemplate": "Loading template",
  "sendCopy": "Send a copy to my email address.",
  "copyLink": "Copy link",
  "personalDetails": "Personal details",
  "accountingAndTaxConfig": "Accounting configuration",
  "comercialInformation": "Commercial information",
  "addContact": "Add contact",
  "noContacts": "Without results 🔦",
  "noContactsDescription": "Try another name or adjust your search filters.",
  "noContactsFilterDescription": "There are no contacts that meet the selected criteria.",
  "loadingTemplate": "Loading email template",
  "invalidEmail": "Invalid email",
  "subject": "Subject:",
  "emailSent": "Email sent",
  "errorSendingEmail": "Error sending email",
  "phoneRequired": "Phone required",
  "messageRequired": "Message required",
  "subjectRequired": "Subject required",
  "toRequired": "To required",
  "sendWhatsapp": "Send by WhatsApp",
  "message": "Message",
  "contactLoadError": "Error loading contact",
  "contactsLoadError": "Error loading contacts",
  "contactIdRequired": "Contact ID required",
  "updatingContact": "Updating contact...",
  "creatingContact": "Creating contact...",
  "contactUpdated": "Contact updated",
  "contactCreated": "It has been created! ✌",
  "contactUpdatedDescription": "The contact has been updated correctly",
  "contactCreatedDescription": "The contact <b>{{name}}</b> is now available to select when selling.",
  "errorUpdatingContact": "Error updating contact",
  "errorCreatingContact": "Error creating contact",
  "deletingContact": "Deleting contact...",
  "contactDeleted": "Contact deleted",
  "contactDeletedDescription": "The contact has been deleted correctly",
  "errorDeletingContact": "Error deleting contact",
  "contactDeactivated": "Contact deactivated",
  "contactDeactivatedDescription": "The contact has been deactivated correctly",
  "contactActivated": "Contact activated",
  "contactActivatedDescription": "The contact has been activated correctly",
  "deactivatingContact": "Deactivating contact...",
  "activatingContact": "Activating contact...",
  "errorDeactivatingContact": "Error deactivating contact",
  "errorActivatingContact": "Error activating contact",
  "deleteClient": "Do you want to delete this contact?",
  "deleteClientDescription": "If you confirm that you want to delete <b>{{name}}</b>, it will be permanently deleted from your contacts.",
  "confirmDeleteContact": "Yes, I want to delete",
  "keepContact": "Keep contact",
  "linkCopied": "Link copied",
  "copyLink": "Copy link",
  "numerationElectronicIntermitenceError": "Invoice stamping with intermittency. We will notify you when service is available.",
  "creditLimit": "Credit limit",
  "creditLimit.error": "Limit: 12 characters",
  "creditLimit.notification.title": "Credit limit exceeded 🚫",
  "creditLimit.notification.description": "With this sale customer exceeds {} of the limit you assigned to him, you can register the total payment or {}",
  "creditLimit.notification.link": "learn how to increase a credit ↗",
  "creditLimit.field.tooltip": "Indicates the maximum amount that this client may have on credit",
  "creditLimit.popover.title": "Configure credit limits ✍️",
  "creditLimit.popover.description": "You can now create your clients and define a personalized credit limit for them",
  "learnMore": "Learn more",
  "goToHelpArticle": "Go to help article",
  "goBackToInvoice": "Back to Check in",
  "controlYourCredits": "Control your credits! 🌟",
  "defineACreditLimit": "Define a credit limit for each client and the system will alert you if you exceed it when selling.",
  "creditLimit.notification.description.zero": "With this sale customer exceeds the limit you assigned to him, you can register the total payment or {}",
  "phone-mobile": "Phone/Mobile",
  "warehouseChangeMessage": "You can switch between warehouses to review the products in each one.",
  "warehouseChangeErrorNew": 'Your are missing the "view detail" permission in warehouses to check the others.',
  "youHavePendingInvoices": "You have sales pending synchronization",
  "youHavePendingInvoices.description": "To avoid losing the register of your sales, we suggest you to synchronize them before signing out.",
  "closeWithoutSyncing": "Sign out without synchronizing",
  "syncInvoices": "Sinchronize sales",
  "confirmationCode": "Confirmation code",
  "confirmationCodeError": "The code is invalid, try again.",
};

export default dictionary;
