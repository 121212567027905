import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useSelector, useDispatch } from 'react-redux';
import { get, isArray, isNumber } from 'lodash'
import { BigNumber } from "bignumber.js";

import * as mutations from '../../../graphql/mutations'
import * as itemsDB from '../../../database/itemsDB'
import { refresh, updateItemImage } from '../../../reducers/items';
import { addItem } from '../../../reducers/activeInvoice';
import { itemQuantity } from '../../../selectors/activeInvoice';
import { APIGraphqlSelector, station } from '../../../selectors/app';
import { decimalPrecision, isActiveNoIvaDay } from '../../../selectors/company';

import { Icon } from '@alegradev/smile-ui-react';
import { sendNewGTMEvent } from '../../../reducers/company';
import { useFormat } from '../../../hooks/useFormat';

const calculateItemValues = (item, decimal, isNoIvaDays) => {
  let subtotal = new BigNumber(0);
  let discount = new BigNumber(0);
  let iepsValue = new BigNumber(0);
  let taxes = {};

  const itemQuantity = new BigNumber(1);
  const itemPrice = new BigNumber(get(item, 'price.0.price', 0));
  const itemDiscountPercent = new BigNumber(!!isNumber(get(item, 'discount.discount', null))
    ? get(item, 'discount.discount')
    : !!get(item, 'discount', null)
      ? get(item, 'discount') : 0
  );

  const itemSubtotal = itemQuantity.multipliedBy(itemPrice);
  subtotal = subtotal.plus(itemSubtotal);

  const itemDiscount = !!item.discount
    ? itemDiscountPercent.dividedBy(100).multipliedBy(itemSubtotal)
    : new BigNumber(0);
  const discountSubtotal = itemSubtotal.minus(itemDiscount);
  discount = discount.plus(itemDiscount)

  if (!!item.tax) {
    const taxesArray = isArray(item.tax)
      ? item.tax : [item.tax]

    const haveIEPStax = taxesArray.some(t => get(t, "type", '') === "IEPS");

    iepsValue = taxesArray.reduce((acc, t) => {
      if (get(t, "type", '') === "IEPS") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(itemSubtotal))
      }
      return acc
    }, new BigNumber(0))

    taxesArray.map(t => {
      if (isNoIvaDays && !!get(item, 'hasNoIvaDays') && get(t, 'type') === 'IVA')
        return null

      if (get(t, 'status') !== 'active')
        return null
      const isTaxIVA = get(t, 'type') === 'IVA';
      const taxValue = new BigNumber(get(taxes, `${t.id}.value`, 0));
      const taxPercent = new BigNumber(t.percentage);
      let tax = new BigNumber(0);

      if (haveIEPStax && isTaxIVA) {
        tax = taxPercent.dividedBy(100).multipliedBy(discountSubtotal.plus(iepsValue))
      } else {
        tax = taxPercent.dividedBy(100).multipliedBy(discountSubtotal)
      }

      taxes[t.id] = !!taxes[t.id]
        ? {
          ...taxes[t.id],
          value: taxValue.plus(tax)
        }
        : {
          value: tax,
          name: `${t.name} (${t.percentage}%)`
        }
      return null;
    })
  }

  const discSubtotal = subtotal.minus(discount);

  return {
    price: itemPrice.decimalPlaces(decimal),
    discount: itemDiscountPercent.toNumber(),
    discountValue: itemDiscount.decimalPlaces(decimal),
    quantity: itemQuantity.toNumber(),
    taxes: Object.values(taxes)
      .map(tax => tax.name).join(', '),
    total: discSubtotal.plus(
      Object.values(taxes)
        .map(tax => tax.value)
        .reduce((p, c) => p.plus(c), new BigNumber(0))
    ).decimalPlaces(decimal),
  }
}

const Item = ({ item, flippedProps }) => {
  const dispatch = useDispatch();
  const imgRef = useRef()
  const [loading, setLoading] = useState(false)
  const [loadError, setLoadError] = useState(false)
  const [imgURL, setImgURL] = useState(
    !!get(item, 'images.length') && get(item, `images.${get(item, 'images.length', 1) - 1}.url`, null)
  )
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    if (imgRef.current)
      setLoading(!imgRef.current.complete)
  }, [])

  const quantity = useSelector(itemQuantity(item.id));
  const { fmt, decimal } = useFormat();
  const { idWarehouse } = useSelector(station);
  const isNoIvaDays = useSelector(isActiveNoIvaDay)

  const onFavorite = async () => {
    try {
      await itemsDB.update(item.id, { isFavorite: !item.isFavorite })
      dispatch(refresh())
      dispatch(sendNewGTMEvent('pos-search-item-favorite'))
      await APIGraphql(graphqlOperation(mutations.updateFavoriteItem, {
        item: {
          id: item.id,
          isFavorite: !item.isFavorite
        }
      }))
    } catch {
    }
  }

  const itemValues = calculateItemValues(item, decimal, isNoIvaDays)

  let availableQuantity = -1;
  const warehouses = get(item, 'inventory.warehouses')
  if (!!warehouses) {
    const stationWarehouse = warehouses.find(warehouse => +get(warehouse, 'id') === +idWarehouse)

    if (!!stationWarehouse) {
      availableQuantity = +get(stationWarehouse, 'availableQuantity', 0);
    }
  }

  return (
    <div
      {...flippedProps}
      className={`item-view d-flex flex-column bg-white user-select-none
        position-relative ${quantity > 0 ? 'active' : ''}`
      }
      data-testid="item-view"
      onClick={() => dispatch(addItem(item))}
    >
      {(!!get(item, 'reference.reference') || !!get(item, 'reference')) && (
        <p className="item-view__reference position-absolute text-primary bg-white">
          {!!get(item, 'reference.reference')
            ? get(item, 'reference.reference') : !!get(item, 'reference')
              ? get(item, 'reference') : ''
          }
        </p>
      )}

      <button
        type="button"
        className="item-view__favorite position-absolute btn button-transparent rounded-0 py-0 px-1"
        onClick={event => {
          onFavorite();
          event.stopPropagation();
        }}
      >
        <Icon icon='star' color='white' extraClass={item.isFavorite ? "item-favorite" : ""} />
      </button>

      {!!isNoIvaDays && !!get(item, 'hasNoIvaDays') && (
        <p className="item-view__no-iva-tag position-absolute">
          {I18n.get('noIvaDay', 'Día sin IVA')}
        </p>
      )}

      <div className="item-view__image-zone position-relative overflow-hidden">

        {availableQuantity >= 0 && (
          <p className="item-view__quantity position-absolute m-0 bg-white">
            {`Inv ${availableQuantity}`}
          </p>
        )}

        {!!imgURL
          ? (
            <>
              {!!loading && (
                <div className="item-view__empty-image w-100 h-100 d-flex align-items-center justify-content-center position-absolute">
                  <Icon icon='loader-2' animated extraClass=" icon-primary icon x2" />
                </div>
              )}

              <img
                ref={imgRef}
                src={imgURL} alt=""
                className={`w-100 h-100 bg-white ${loadError ? 'd-none' : ''}`}
                onLoad={() => setLoading(false)}
                onError={async () => {
                  setLoadError(true)
                  setLoading(true)
                  try {
                    const { payload: newItem } = await dispatch(updateItemImage({ id: item.id }))
                    setImgURL(!!get(newItem, 'images.length') && get(newItem, `images.${get(newItem, 'images.length', 1) - 1}.url`, null))
                    setLoadError(false)
                  } catch {
                    setImgURL(false)
                    setLoadError(true)
                  }
                  setLoading(false)
                }}
              />
            </>
          )
          : (
            <div className="item-view__empty-image w-100 h-100 d-flex align-items-center justify-content-center position-absolute">
              <Icon icon='tag' extraClass="icon-gray icon x4" />
            </div>
          )
        }

      </div>

      <p className="item-view__name text-center text-truncate text-wrap">
        {item.name}
      </p>
      <p className="item-view__price text-center">
        {itemValues.total.toFormat(decimal, fmt)}
      </p>

      {quantity > 0 && (
        <div className="item-view__count position-absolute rounded-circle d-flex justify-content-center align-items-center text-primary">
          {quantity}
        </div>
      )}
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object,
}

export default Item;
