import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import YouTube from 'react-youtube';

import * as mutations from '../../graphql/mutations';
import {
  companySelector,
  country as countrySelector,
  idCompanySelector,
  origin as originSelector,
} from '../../selectors/company';
import { setUser } from '../../reducers/auth';
import { openModal } from '../../reducers/modals';
import { countryByVersion } from '../../utils';
import { formError } from '../../utils/errors';
import BackgroundSVG from '../images/background.png';
import Body from '../forms/company/OnboardingSettings';
import {
  transformOnboarding,
  validateOnboarding,
} from '../forms/company/utils';
import { replaceAndParse, isClothingStore } from '../../utils';
import regimes from '../countriesData/general/regimes';
import Header from './Header';
import Bottom from './Bottom';
import { sendGTMEvent, sendNewGTMEvent } from '../../reducers/company';
import alegraAPI from '../../reducers/alegraAPI';
import { APIGraphqlSelector } from '../../selectors/app';
import { editCompanyValue } from '../settings/General/utils';
import { userIsElegibleForTip } from '../settings/utils';
import { getDefaultCategoryIdPurchases, getDefaultCategoryIdToCreateCharge } from '../../utils/tips';
import { billsCategoriesWithoutFilter, pasivesCategoriesWithoutFilter } from '../../selectors/categories';
import { COUNTRIES } from '../../utils/enums/countries';
import { userSelector } from '../../selectors/auth';

const sectorsToActivateTips = ['Alimentación / Gastronomía', 'Cuidado personal / Estética', 'Hotelería / Turismo']

const Onboarding = () => {
  const [step, setStep] = useState(0);
  const [prevValues, setPrevValues] = useState(null);
  const [started, setStarted] = useState(false);
  const [videoIsPlayed, setVideoIsPlayed] = useState(false);
  const [videoIsWatchedUntilEnd, setVideoIsWatchedUntilEnd] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const country = useSelector(countrySelector);
  const { registryDate } = useSelector(companySelector);
  const companyId = useSelector(idCompanySelector);
  const categories = useSelector(pasivesCategoriesWithoutFilter);
  const categoriesPurchases = useSelector(billsCategoriesWithoutFilter);
  const {email: userEmail} = useSelector(userSelector);

  const defaultCategoryIdToCreateCharge = getDefaultCategoryIdToCreateCharge(categories, country, registryDate);
  const defaultCategoryIdPurchases = getDefaultCategoryIdPurchases(categoriesPurchases, registryDate);

  const isOpenSeenWelcomeModal = useSelector((state) =>
    get(state, 'modals.welcome.isOpen', false)
  );
  const origin = useSelector(originSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    if(started) dispatch(sendNewGTMEvent('pos-onboarding-started'));
    setStarted(true);
  }, [started]);

  const onNext = (values) => {
    if (!!values) setPrevValues(values);
    setStep(Math.min(step + 1, 1));
  };

  const onPrevius = () => setStep(Math.max(step - 1, 0));

  const submit = async (values) => {
    try {
      await alegraAPI.put('/company', transformOnboarding(values, country, userEmail));
      const response = await APIGraphql(
        graphqlOperation(mutations.updateUser, {
          user: {
            company: {
              localSettings: {
                seenTutorialWizardPOS: true,
              },
            },
          },
        })
      );
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')));

      onNext(values);
      dispatch(sendGTMEvent('company-information-filled'));
      dispatch(
        sendNewGTMEvent('pos-account-information-filled', {
          sector: get(values, 'sector.key', ''),
          phoneNumber: !!get(values, 'phone'),
        })
      );
      if (sectorsToActivateTips.includes(get(values, 'sector.value', '')) && userIsElegibleForTip({country, idCompany: companyId, registryDate})) {
        try {
          const categoryId = defaultCategoryIdToCreateCharge
          const categoryIdPurchases = country === COUNTRIES.COSTA_RICA 
            ? defaultCategoryIdPurchases
            : categoryId

          const aditionalCharge = await alegraAPI.post('/additional-charges/', {
            "name": I18n.get('tip', 'Propina'),
            "code": "01",
            "description": "Cantidad de dinero que se da voluntariamente para agradecer un servicio.",
            "percentage": 0,
            "categoryPurchasesId": categoryIdPurchases,
            "categorySalesId": categoryId,
            "status": "active",
            "companyId": companyId
          });

          const additionalChargeId = get(aditionalCharge.data, 'id', '0')
          if (additionalChargeId === '0') {
            throw new Error('No se pudo crear el cargo adicional')
          }
        
    
          const tipsSettings = {
            localSettings: {
              tipEnabled: true,
              tipAdditionalChargeId: additionalChargeId,
              tipAccount: categoryId,
              tipAccountPurchases: categoryIdPurchases,
            }
          }
    
          const response = await editCompanyValue(APIGraphql, tipsSettings);
          if (response) {
            dispatch(setUser(get(response, 'data.updateUser')))
            dispatch(sendNewGTMEvent('pos-tip-configured', {
              action: 'activate'
            }))
          }


        } catch (error) {
          console.error('Error while creating additional charge', error)
        }
      }
    } catch (error) {
      onNext(values);
      return formError(
        error,
        I18n.get(
          'companyUpdateError',
          'hubo un error a la hora de configurar la compañía'
        )
      );
    }
  };

  return (
    <div className='main onboarding'>
      <div className='px-5 py-4 w-100 h-100 d-flex justify-content-center align-items-center'>
        <div
          className='bg-white border h-100 d-flex flex-column onboarding-inner'
          style={{
            background: `url(${BackgroundSVG}) no-repeat`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: 'calc(100% - 6rem)',
          }}
        >
          <Form
            onSubmit={submit}
            validate={(values) => validateOnboarding(values, country)}
            initialValues={
              !!prevValues
                ? prevValues
                : {
                    phone: countryByVersion(country).dialCode + '.',
                    phoneCountry: countryByVersion(country),
                    regime: !!regimes(country) ? regimes(country)[0] : null,
                  }
            }
          >
            {({ handleSubmit, submitting, form, values }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className='d-flex flex-column'
              >
                <div className='onboarding-header'>
                  <Header step={step} />
                </div>

                <div className='onboarding-body pt-3 pt-sm-5 px-2'>
                  <div className='onboarding-inner-body h-100 d-flex flex-column align-items-center'>
                    <div className='d-flex flex-column align-items-center form-body'>
                      <h1 className='text-capitalize-first text-center'>
                        {step === 0 &&
                          (isClothingStore(country, origin)
                            ? I18n.get(
                                'onboardingWizardTitleStep0ClothingStore',
                                '¡Agrega los datos de tu tienda de ropa!'
                              )
                            : I18n.get(
                                'onboardingWizardTitleStep0',
                                '¡Configura los datos de tu negocio!'
                              ))}
                        {step === 1 &&
                          I18n.get(
                            'onboardingWizardTitleStep1',
                            '¡Toma el control de tu punto de venta!'
                          )}
                      </h1>
                      <div className='divider' />
                      <p className='text-capitalize-first h4 text-muted text-center onboarding-subtitle'>
                        {step === 0 &&
                          I18n.get(
                            'onboardingWizardSubtitleStep0',
                            'Es el primer paso para personalizar tu punto de venta'
                          )}
                        {step === 1 &&
                          (isClothingStore(country, origin)
                            ? replaceAndParse(
                                I18n.get(
                                  'onboardingWizardSubtitleStep1ClothingStore',
                                  'Dale play y descubre lo fácil que vas a <b>gestionar tu tienda de ropa</b> a partir de hoy'
                                ),
                                []
                              )
                            : replaceAndParse(
                                I18n.get(
                                  'onboardingWizardSubtitleStep1',
                                  'Dale play y descubre lo fácil que vas a gestionar tu negocio con Alegra POS'
                                ),
                                []
                              ))}
                      </p>

                      {step === 0 && <Body form={form} values={values} />}

                      {step === 1 && (
                        <div className='video-intro-outer'>
                          <div className='video-intro'>
                            <YouTube
                              videoId={I18n.get(
                                'onboarding.video.id',
                                'sFZpQMY6P3Q'
                              )}
                              onPlay={() => setVideoIsPlayed(true)}
                              onEnd={() => setVideoIsWatchedUntilEnd(true)}
                            />
                            {/* <iframe
                              id="video-wizard-clicked"
                              className="w-100"
                              src={I18n.get('onboarding.video.url', 'https://www.youtube.com/embed/sFZpQMY6P3Q')}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={I18n.get('videoIntroductionPOS', 'Video Introducción POS')}
                            /> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='onboarding-bottom d-flex justify-content-center align-items-center'>
                  <Bottom
                    nextId={step === 1 ? 'finish-wizard-clicked' : null}
                    nextText={I18n.get('continue', 'continuar')}
                    nextAsSubmit={step === 0}
                    onNext={
                      step === 0
                        ? null
                        : () => {
                            history.push('/');
                            if (!isOpenSeenWelcomeModal)
                              dispatch(openModal({ modal: 'welcome' }));
                            dispatch(
                              sendGTMEvent('onboarding-finished', {
                                videoIsPlayed,
                                videoIsWatchedUntilEnd,
                              })
                            );

                            dispatch(
                              sendNewGTMEvent('pos-video-tour-completed', {
                                status: videoIsWatchedUntilEnd
                                  ? 'completed'
                                  : videoIsPlayed
                                    ? 'started'
                                    : 'skipped',
                              })
                            );
                          }
                    }
                    submitting={submitting}
                    onPrevious={step > 0 ? () => onPrevius() : null}
                  />
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
