// src/components/inventoryAdjustment/columns.js
import React, { useMemo } from "react";
import { Field } from "react-final-form";
import { capitalize, get, has } from "lodash";
import { I18n } from "@aws-amplify/core";
import BigNumber from "bignumber.js";

import { renderField, renderSelect } from "../fields/V0/Fields";
import { styleBigNumber } from "../../../utils";
import { Icon } from '@alegradev/smile-ui-react';

const OBJECTIVES = [
  {
    key: "in",
    label: "increment",
  },
  {
    key: "out",
    label: "decrement",
  },
];

const useColumns = (props) => {
  const { decimal, fmt, form, items, loading, error, handleInputChange, setOpenModal, setSelectedRowName } = props;

  const columns = useMemo(() => [
    {
      header: () => (
        <div style={{ textAlign: "start" }}>
          {capitalize(I18n.get("product", "Producto"))}
        </div>
      ),
      Render: ({ row }, name) => {
        if (!has(row, 'item')) {
          return (
            <div style={{ width: "20rem" }}>
              <Field
                name={`${name}.item`}
                component={renderSelect}
                className="m-0"
                placeholder={(
                  <div className="d-flex align-items-center g-3">
                    <Icon icon='search' color="#C3C3C3" />
                    <p className="m-0 ml-2">
                      {I18n.get("addItem", "Agregar producto")}
                    </p>
                  </div>
                )}
                options={items}
                isLoading={loading}
                isClearable={false}
                isSearchable={true}
                cacheOptions={false}
                noOptionsMessage={() =>
                  error ? I18n.get('errorLoading', 'Error cargando productos') :
                    items.length === 0
                      ? I18n.get(
                        'createItemToSelect',
                        'Crea un nuevo producto para seleccionarlo'
                      )
                      : I18n.get(
                        'noItemsFound',
                        'No se encontraron productos'
                      )
                }
                onInputChange={handleInputChange}
                getOptionLabel={(option) =>
                  `${option.name}${option.reference?.reference ? ` (${option.reference.reference})` : option.reference ? ` (${option.reference})` : ''}`
                }
                getOptionValue={(option) => option.id}
                menuPosition="absolute"
                hideDropdownIndicator
                required
              />
            </div>
          )
        }
        return (
          <div
            className='d-flex align-items-center'
            style={{ gap: '2rem', textAlign: 'start' }}
          >
            <div className='new-inventory-adjustment-image-container'>
              {!!get(row, 'item.images.length') ? (
                <img
                  src={get(
                    row,
                    `item.images.${get(row, `item.images.length`, 1) - 1}.url`
                  )}
                  alt=''
                  className='w-100 h-100 bg-white'
                />
              ) : !!get(row, 'images.length') ? (
                <img
                  src={get(
                    row,
                    `images.${get(row, `images.length`, 1) - 1}.url`
                  )}
                  alt=''
                  className='w-100 h-100 bg-white'
                />
              ) : (
                <div className='item-view__empty-image w-100 h-100 d-flex align-items-center justify-content-center'>
                  <Icon icon='tag' extraClass='icon-gray icon x2' />
                </div>
              )}
            </div>
            <div className='d-flex flex-column' style={{ gap: '0.5rem' }}>
              <p className='h5 font-weight-bold'>{`${get(row, 'item.name') ?? get(row, 'name')}`}</p>
              <p className='h5 text-muted'>{`${I18n.get('currentQuantity', 'Cantidad actual')}: ${get(row, 'inventory.availableQuantity') ?? get(row, 'item.inventory.availableQuantity')}`}</p>
            </div>
          </div>
        );
      }
    },
    {
      header: capitalize(I18n.get("cost", "Costo")),
      Render: ({ row }, name) => {
        if (!(get(row, 'objective.key') === 'out') || get(row, 'allowCostChange'))
          return (
            <div style={{ width: "15rem" }}>
              <Field
                name={`${name}.cost`}
                component={renderField}
                className="m-0"
                initialValue={get(row, 'cost') || get(row, 'item.inventory.unitCost')}
                type="number"
                disabled={!get(row, 'item')}
                required
              />
            </div>
          )

        return (
          <Field
            name={`${name}.cost`}
            render={() => (
              <div
                className="form-control d-flex align-items-center justify-content-between pointer"
                style={{ gap: "1rem", width: "15rem", background: "#e9ecef" }}
                onClick={() => {
                  setOpenModal(true);
                  setSelectedRowName(name);
                }}
              >
                <Icon icon='pencil' />
                <p
                  className="text-truncate m-0 styled-hover"
                  hovercontent={get(row, 'item.inventory.unitCost')}
                >
                  {get(row, 'item.inventory.unitCost')}
                </p>
              </div>
            )}
          />
        )
      },
    },
    {
      header: capitalize(I18n.get("objective", "Objetivo")),
      Render: ({ row }, name) => {
        return (
          <div style={{ width: "13rem" }}>
            <Field
              name={`${name}.objective`}
              component={renderSelect}
              className="m-0"
              options={OBJECTIVES}
              defaultValue={OBJECTIVES[0]}
              getOptionLabel={(option) => I18n.get(option.label, option.label)}
              getOptionValue={(option) => option.key}
              isSearchable={true}
              menuPosition="absolute"
              disabled={!get(row, 'item')}
              required
            />
          </div>
        )
      },
    },
    {
      header: capitalize(I18n.get("quantity", "Cantidad")),
      Render: ({ row }, name) => {
        const incrementQuantity = () => {
          form.change(`${name}.quantity`, +form.getFieldState(`${name}.quantity`)?.value + 1)
        }

        const decrementQuantity = () => {
          if (+form.getFieldState(`${name}.quantity`)?.value > 1)
            form.change(`${name}.quantity`, +form.getFieldState(`${name}.quantity`)?.value - 1)
          return;
        }

        const quantity = row?.quantity || 1;

        return (
          <div style={{ width: "15rem" }}>
            <Field
              name={`${name}.quantity`}
              className="m-0"
              component={renderField}
              initialValue={quantity}
              placeholder="0"
              type="number"
              incrementIcon
              onIncrement={() => incrementQuantity()}
              onDecrement={() => decrementQuantity()}
              disabled={!get(row, 'objective') || !get(row, 'item')}
              validate={value => {
                if (parseInt(value) <= 0)
                  return 'noZeroAllowed'
              }}
              hideErrorLabel
              required
            />
          </div>
        )
      },
    },
    {
      header: capitalize(I18n.get("finalQuantity", "Cantidad final")),
      Render: ({ row }) => {
        const currentQuantity = +(get(row, 'inventory.availableQuantity') ?? get(row, 'item.inventory.availableQuantity', 0));
        const finalQuantity = get(row, 'objective.key') === 'in' ? currentQuantity + (+get(row, 'quantity', 0)) : currentQuantity - (+get(row, 'quantity', 0));
        return (
          <div style={{ width: "8rem" }}>
            <p className="h4 text-muted text-truncate styled-hover m-0 p-0" hovercontent={finalQuantity || "-"}>
              {finalQuantity || '-'}
            </p>
          </div>
        )
      },
    },
    {
      header: capitalize(I18n.get("adjustedTotal", "Total Ajustado")),
      Render: ({ row }) => {
        const totalAdjusted = new BigNumber(get(row, 'cost', 0) * get(row, 'quantity', 0) * (get(row, 'objective.key') === 'in' ? 1 : -1));
        const totalAdjustedFormatted = totalAdjusted.toFormat(decimal, fmt);
        const {
          intPart: totalIntPart,
          decPart: totalDecPart
        } = styleBigNumber(totalAdjustedFormatted, decimal);
        return (
          <div
            className="h4 text-truncate styled-hover d-flex m-0 p-0"
            hovercontent={totalAdjustedFormatted}
            style={{ width: "10rem" }}
          >
            <p className="text-truncate m-0">
              {totalIntPart}
            </p>
            <p className="text-truncate text-muted m-0">
              {totalDecPart}
            </p>
          </div>
        )
      },
    },
  ], [decimal, fmt, form, items, loading, error, handleInputChange, setOpenModal, setSelectedRowName]);

  return columns;
};

export default useColumns;
