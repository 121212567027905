import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import Loader from 'react-content-loader';
import dayjs from 'dayjs';

import {
  decimalPrecision,
  timezone as tzSelector,
  dateFormat as dateFormatSelector,
  idCompanySelector,
  country as countrySelector,
  companySelector
} from '../../../selectors/company';
import {
  paymentsLoading,
  payments as paymentsSelector,
  baseAmount,
  startDate,
} from '../../../selectors/shifts';
import { lowerCase } from 'lodash';
import { Tooltip } from '@alegradev/smile-ui-react';
import { Icon, Typography } from '@alegradev/smile-ui-react';
import { userIsElegibleForTip } from '../../settings/utils';
import { useFormat } from '../../../hooks/useFormat';

const textLoader = () => (
  <Loader title="" height={14} width={72}>
    <rect width='100%' height='100%' x="0" y="0" rx="5" ry="5" />
  </Loader>
)


const NewDetails = () => {
  const loading = useSelector(paymentsLoading);
  const payments = useSelector(paymentsSelector);
  const base = useSelector(baseAmount);
  const start = useSelector(startDate);
  const timezone = useSelector(tzSelector);
  const dateFormat = useSelector(dateFormatSelector);
  const country = useSelector(countrySelector);
  const idCompany = useSelector(idCompanySelector);
  const { registryDate } = useSelector(companySelector);
  const { decimal, fmt } = useFormat();

  const cash = new BigNumber(payments.cash);
  const debit = new BigNumber(payments.debit);
  const credit = new BigNumber(payments.credit);
  const transfer = new BigNumber(payments.transfer);
  const refunds = new BigNumber(payments.refunds);
  const inPayment = new BigNumber(payments.inPayment);
  const outPayment = new BigNumber(payments.outPayment);
  const totalsTipCash = new BigNumber(payments.tipsTotalCash);
  const totalsTipDebit = new BigNumber(payments.tipsTotalDebit);
  const totalsTipCredit = new BigNumber(payments.tipsTotalCredit);
  const totalsTipTransfer = new BigNumber(payments.tipsTotalTransfer);

  const totalsTipOther = totalsTipDebit.plus(totalsTipCredit).plus(totalsTipTransfer);

  const cashPaymentSales = new BigNumber(0).plus(cash).plus(debit).plus(credit).plus(transfer);
  // const creditSales = new BigNumber(payments.creditSales);
  const total = new BigNumber(base)
    .plus(cash)
    .plus(totalsTipCash)
    .plus(inPayment)
    .minus(refunds)
    .minus(outPayment)

  return (
    <div className="d-flex flex-column">
      <div className="close-shift-date-container d-flex align-items-center">
        <span className="text-capitalize-first">
          {I18n.get('startDate', 'fecha de inicio')}
        </span>
        <span className="close-shift-date-container__date">
          {!!start ? dayjs.tz(start, timezone).format(I18n.get(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))) : null}
        </span>
      </div>

      <div className="close-shift-sales-container">
        {/* <p className="close-shift-sales-container__sales-info">
          <span>{I18n.get('immediatePaymentSale', 'Ventas a contado')}</span>
          <span>
            {!!loading
              ? textLoader()
              : cashPaymentSales.toFormat(decimal)}
          </span>
        </p> */}
        {/* <p className="close-shift-sales-container__sales-info">
          <span>{I18n.get('creditPaymentSales', 'Ventas a crédito')}</span>
          <span>
            {!!loading
              ? textLoader()
              : creditSales.toFormat(decimal)}
          </span>
        </p> */}
        
          <p className="close-shift-sales-container__sales-info">
            <span className='d-flex align-items-center'>
              <p className='mb-0 m-2'>{I18n.get('total', 'total') + ' ' + lowerCase(I18n.get('of', 'de') + ' ' + I18n.get('sales', 'ventas'))}</p>
              {
                userIsElegibleForTip({country, idCompany}) && (
                  <Tooltip
                    overlay={
                      I18n.get('HelpTooltipTotalSales','Corresponde a las ventas del turno, sin incluir propinas u otros movimientos de efectivo.')
                    }
                  >
                    <Icon icon='help' color='#00b19d ' />
                  </Tooltip>
                )
              }              
            </span>
            <span>
              {!!loading
                ? textLoader()
                : cashPaymentSales.toFormat(decimal, fmt)}
            </span>
          </p>
      </div>

      <div className="close-shift-info-container">
        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('base', 'base inicial')}</span>
          <span>{new BigNumber(base).toFormat(decimal, fmt)}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('cashSales', 'ventas en efectivo')}</span>
          {!!loading
            ? textLoader()
            : cash.toFormat(decimal, fmt)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</span>
          {!!loading
            ? textLoader()
            : debit.toFormat(decimal, fmt)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</span>
          {!!loading
            ? textLoader()
            : credit.toFormat(decimal, fmt)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('transferSales', 'ventas por transferencias')}</span>
          {!!loading
            ? textLoader()
            : transfer.toFormat(decimal, fmt)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('refundCash', 'Devolución de dinero')}</span>
          {!!loading
            ? textLoader()
            : <span className="text-danger">{refunds.eq(0) ? '' : '-'}{refunds.toFormat(decimal, fmt)}</span>
          }
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('invoiceCash', 'ingresos de efectivo')}</span>
          {!!loading
            ? textLoader()
            : inPayment.toFormat(decimal, fmt)}
        </div>

        {(totalsTipCash.isGreaterThan(0) || totalsTipOther.isGreaterThan(0)) && 
          <>
            <div className="d-flex align-items-center justify-content-between w-100">
                <span className="text-capitalize-first">{I18n.get('shift.tipsCash', 'Propinas en efectivo')}</span>
                {!!loading
                  ? textLoader()
                  : totalsTipCash.toFormat(decimal, fmt)
                }
              </div>

              <div className="d-flex align-items-center justify-content-between w-100">
                <span className="text-capitalize-first">{I18n.get('shift.tipsOther', 'Propinas en otros medios')}</span>
                {!!loading
                  ? textLoader()
                  : totalsTipOther.toFormat(decimal, fmt)
                }
              </div>
          </>
        }
        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('expenseCash', 'retiros de efectivo')}</span>
          {!!loading
            ? textLoader()
            : <span className="text-danger">{outPayment.eq(0) ? '' : '-'}{outPayment.toFormat(decimal, fmt)}</span>}
        </div>

        <div className="d-flex align-items-center justify-content-between inter-semibold w-100">
          <span className="text-capitalize-first">{I18n.get('totalOfMovements', 'Total de movimientos')}</span>
          {!!loading
            ? textLoader()
            : total.plus(debit).plus(credit).plus(transfer).plus(totalsTipOther).toFormat(decimal, fmt)}
        </div>
      </div>

      <div className="close-shift-cash-info-container">
        <span>{I18n.get('cashExpectedInClosingShift', 'Dinero esperado en caja')}</span>
        {!!loading
            ? textLoader()
            : total.toFormat(decimal, fmt)}
      </div>
    </div>
  )
}

export default NewDetails;