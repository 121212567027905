import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get, replace } from 'lodash';

import * as mutations from '../../graphql/mutations';
import { handleError } from '../../utils/errors';
import { getNumber, getNumberAndPrefix } from '../../utils/invoices';
import { openModal, closeModal } from '../../reducers/modals';
import { setTypeToPrint } from '../../reducers/print';
import { setInvoice } from '../../reducers/editInvoice';
import { syncOffline } from '../../reducers/activeInvoice';
import {
  shiftsEnabled,
  country as countrySelector,
  companySelector,
} from '../../selectors/company';
import { hasPermissionTo } from '../../selectors/auth';
import { isOpen, current } from '../../selectors/shifts';
import { getInvoiceNumerations as numerationSelector } from '../../selectors/numerations';
import Confirmation from '../modals/confirmation/Confirmation';
import VoidInvoiceCause from '../modals/voidInvoiceCause/VoidInvoiceCause';
import {
  getLegalStatusKey,
  getVoidTitle,
  isElegibleForApplyRefund,
  showVoidIcon,
} from './utils';
import alegraAPI from '../../reducers/alegraAPI';
import {
  getHoursBetweenDates,
  getLocalDate,
  replaceAndParse,
  toast,
} from '../../utils';
import parse from 'html-react-parser';
import { APIGraphqlSelector } from '../../selectors/app';
import { sendNewGTMEvent } from '../../reducers/company';
import { COUNTRIES } from '../../utils/enums/countries';
import { Button, Tooltip, Typography, useModal } from '@alegradev/smile-ui-react';
import { station as stationSelector } from '../../selectors/app';

import { Icon } from '@alegradev/smile-ui-react';
import ShareInvoice from '../modals/ShareInvoice/ShareInvoice';
import { newShareInvoiceAvailable } from '../modals/ShareInvoice/utils';

const DetailHeader = ({ invoice, onListView, onRefresh, onEdit }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const can = useSelector(hasPermissionTo);
  const [loading, setLoading] = useState(false);
  const [loadingStamp, setLoadingStamp] = useState(false);
  const [error, setError] = useState(null);
  const confirmationOpen = useSelector((state) =>
    get(state, 'modals.voidInvoice.isOpen', false)
  );
  const isShiftEnabled = useSelector(shiftsEnabled);
  const isShiftOpen = useSelector(isOpen);
  const currentShift = useSelector(current);
  const country = useSelector(countrySelector);
  const numerations = useSelector(numerationSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const company = useSelector(companySelector);
  const station = useSelector(stationSelector);
  const { openModal: openNewModal } = useModal();

  const documentType = get(
    invoice,
    'numberTemplate.documentType',
    'saleTicket'
  );
  const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
  const invoiceStatus = get(invoice, 'status', 'open');

  const canStamp = () => {
    if (!invoice) return false;
    if (
      ![
        'costaRica',
        'argentina',
        'colombia',
        'peru',
        'republicaDominicana',
        'panama',
      ].includes(country)
    )
      return false;

    const isInvoiceStamped = get(invoice, 'stamp.date', null);
    const invoiceNumeration = numerations.find(
      (n) => Number(n.id) === Number(get(invoice, 'numberTemplate.id'))
    );
    const isInProcess = ['republicaDominicana', 'panama'].includes(country)
      ? get(invoice, 'stamp.legalStatus', null) ===
      'STAMPED_AND_WAITING_RESPONSE'
      : false;
    let result =
      invoiceNumeration &&
      !isInvoiceStamped &&
      invoiceNumeration.isElectronic &&
      can('edit-open', 'invoices', true) &&
      !isInProcess;
    return result;
  };

  const canVoid = () => {
    if (!invoice) return false;

    if (
      country === COUNTRIES.MEXICO &&
      invoice?.numberTemplate?.documentType === 'invoice'
    )
      return false;

    let result =
      get(invoice, 'status') !== 'void' &&
      (!isShiftEnabled ||
        (isShiftEnabled &&
          isShiftOpen === true &&
          get(invoice, 'idShift') === get(currentShift, 'idLocal')));

    if (['peru'].includes(country))
      result = result && !get(invoice, 'numberTemplate.isElectronic');

    if (country === 'republicaDominicana')
      result =
        result &&
        !get(invoice, 'stamp') &&
        !get(invoice, 'numberTemplate.isElectronic');

    if (
      country === 'panama' &&
      !!get(invoice, 'stamp') &&
      !!get(invoice, 'stamp.date') &&
      get(invoice, 'numberTemplate.isElectronic')
    )
      result =
        result &&
        getHoursBetweenDates(
          get(invoice, 'stamp.date'),
          getLocalDate(new Date(), country)
        ) <= 180;

    if (country === 'colombia') {
      const numerationIsElectronic = get(
        invoice,
        'numberTemplate.isElectronic'
      );
      const invoiceEmissionAccepted = get(
        invoice,
        'stamp.legalStatus',
        ''
      ).includes('STAMPED_AND_ACCEPTED');

      if (
        isElegibleForApplyRefund(invoice, country, company) &&
        invoiceEmissionAccepted
      )
        result = result && true;
      else if (numerationIsElectronic) result = result && false;
    }

    result = result && can('void', 'invoices');

    return result;
  };

  const canEdit = () => {
    if (!invoice) return false;

    return (
      get(invoice, 'status') === 'open' &&
      !get(invoice, 'stamp') &&
      can('edit-open', 'invoices', true)
    );
  };

  const canSendOrPrint = () => {
    if (!invoice) return false;

    if (country !== 'panama' || !get(invoice, 'numberTemplate.isElectronic'))
      return true;

    const isInvoiceCloses =
      get(invoice, 'stamp.legalStatus', null) === 'STAMPED_AND_ACCEPTED' ||
      get(invoice, 'stamp.legalStatus', null) ===
      'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS';
    return isInvoiceCloses;
  };

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  if (!invoice) return null;

  const getDocumentTypeText = (invoice) => {
    if (country === COUNTRIES.COLOMBIA) {
      if (get(invoice, 'numberTemplate.documentType') === 'invoice') {
        return replaceAndParse(
          I18n.get(
            'invoiceStampedSuccess',
            'Tu {} se envió con éxito a la DIAN y ya se encuentra {}'
          ),
          ['factura electrónica', 'emitida']
        );
      }

      if (get(invoice, 'numberTemplate.documentType') === 'saleTicket') {
        return replaceAndParse(
          I18n.get(
            'invoiceStampedSuccess',
            'Tu {} se envió con éxito a la DIAN y ya se encuentra {}'
          ),
          ['documento POS', 'emitido']
        );
      }

      if (get(invoice, 'numberTemplate.documentType') === 'creditNote') {
        return replaceAndParse(
          I18n.get(
            'invoiceStampedSuccess',
            'Tu {} se envió con éxito a la DIAN y ya se encuentra {}'
          ),
          ['nota de crédito', 'emitida']
        );
      }

      if (
        get(invoice, 'numberTemplate.documentType') === 'incomeAdjustmentNote'
      ) {
        return replaceAndParse(
          I18n.get(
            'invoiceStampedSuccess',
            'Tu {} se envió con éxito a la DIAN y ya se encuentra {}'
          ),
          ['nota de ajuste', 'emitida']
        );
      }
    }
    return I18n.get(
      'invoiceStampedSuccess',
      'La factura electrónica de venta se emitió con éxito'
    );
  };

  const stampInvoice = async () => {
    setLoadingStamp(true);
    const invoicePayments = get(invoice, 'payments', []) || [];
    if (invoicePayments.some((p) => p.status === 'open')) {
      for (const payment of invoicePayments) {
        try {
          await alegraAPI.post(`/payments/${payment.id}/void`);
        } catch (e) {
          // console.log(e);
        }
      }
    }

    try {
      let _invoice = {
        paymentForm: get(invoice, 'paymentForm', 'CASH'),
        paymentMethod: get(invoice, 'paymentMethod', 'CASH'),
        stamp: { generateStamp: true },
      };

      if (country === 'panama')
        _invoice = {
          saleCondition: get(invoice, 'saleCondition', 'CASH'),
          type: 'INTERNAL_OPERATION',
          operationType: 'SALE',
          saleType: 'ORDER',
          stamp: { generateStamp: true },
        };

      await alegraAPI.put('/invoices/' + invoice.id, _invoice);
      if (invoicePayments.length > 0) {
        for (const payment of invoicePayments) {
          try {
            await alegraAPI.post(`/payments/${payment.id}/open`);
          } catch (e) {
            // console.log(e);
          }
        }
      }

      onRefresh();
      toast.success({
        title: I18n.get('stampedSuccessfullyMessage', '¡Ya está! ⚡'),
        subtitle: getDocumentTypeText(invoice),
      });
      setLoadingStamp(false);
    } catch (err) {
      setLoadingStamp(false);
      if (invoicePayments.length > 0) {
        for (const payment of invoicePayments) {
          try {
            await alegraAPI.post(`/payments/${payment.id}/open`);
          } catch (e) {
            // console.log(e);
          }
        }
      }
      toast.error({
        title: 'Error',
        subtitle: parse(
          handleError(
            err.response.data,
            I18n.get(
              'changeInvoiceError',
              'No pudimos modificar la factura. Inténtalo de nuevo'
            )
          )
        ),
      });
    }
  };

  const voidInvoice = async (cause) => {
    setLoading(true);
    setError(null);

    try {
      await APIGraphql(
        graphqlOperation(mutations.cancelInvoice, {
          id: invoice.id,
          payments: !!invoice.payments ? invoice.payments.map((p) => p.id) : [],
          cause,
          idStation: get(station, 'id'),
        })
      );
      if (ref.current) {
        setLoading(false);
        setError(null);
      }
      dispatch(closeModal({ modal: 'voidInvoice' }));
      onRefresh();
    } catch (error) {
      if (ref.current) {
        setLoading(false);
        setError(handleError(error));
      }
    }
  };

  const checkCountry = () => {
    if (country !== 'republicaDominicana') return voidInvoice();

    dispatch(closeModal({ modal: 'voidInvoice' }));
    dispatch(openModal({ modal: 'voidInvoiceCause' }));
  };

  const title = useMemo(() => {
    if (country === COUNTRIES.MEXICO) {
      return getNumber(invoice, country);
    }

    return `<b>${I18n.get('number', 'Número')}</b> ${get(invoice, 'numberTemplate.number', null)}`
  }, [invoice, country]);

  return (
    <>
      <div className='px-4 pt-4 d-flex justify-content-between align-items-top'>
        <div className='d-flex align-items-center '>
          <Icon
            icon='circle-arrow-down-left'
            extraClass='icon-grayblue7 mr-1'
            width='17.5'
            height='17.5'
          />
          {[COUNTRIES.PANAMA].includes(country) ? (
            <>
              <h2 className='title text-capitalize-first'>
                {getNumberAndPrefix(invoice, country)}
              </h2>
              {get(invoice, 'numberTemplate.prefix', null) && (
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  extraClassName='mr-3'
                  withHtml
                  text={`<b>${I18n.get('billingPoint', 'Punto de Facturación')}</b> ${get(invoice, 'numberTemplate.prefix', null)}`}
                />
              )}
            </>
          ) : (
            <div className='invoices-header__numeration'>
              {get(invoice, 'numberTemplate.number', null) && (
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  extraClassName='mr-3'
                  withHtml
                  text={title}
                />
              )}

              {get(invoice, 'numberTemplate.branchOfficeCode', null) && (
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  extraClassName='mr-3'
                  withHtml
                  text={`<b>${I18n.get('branch', 'Sucursal')}</b> ${get(invoice, 'numberTemplate.branchOfficeCode', null)}`}
                />
              )}
            </div>
          )}
        </div>

        <div className='actions d-flex align-items-center'>
          <div className='w-100 d-flex justify-content-around align-items-center flex-wrap'>
            {canStamp() && (
              <>
                <button
                  type='button'
                  className='btn btn-submit text-nowrap absolute-center'
                  onClick={() => [
                    stampInvoice(),
                    dispatch(
                      sendNewGTMEvent('pos-sale-managed', {
                        id: get(invoice, 'id', ''),
                        action: 'emit',
                      })
                    ),
                  ]}
                >
                  {loadingStamp ? (
                    <Icon icon='loader-2' animated extraClass=' icon-white' />
                  ) : (
                    <div className='d-none d-sm-inline'>
                      {I18n.get('emit', 'emitir')}
                    </div>
                  )}
                </button>
              </>
            )}
            {!invoice.offlineStatus && (
              <>
                {canVoid() && (
                  <button
                    type='button'
                    className='btn btn-submit text-nowrap'
                    onClick={() => {
                      isElegibleForApplyRefund(invoice, country, company)
                        ? dispatch(
                          openModal({
                            modal: 'newRefunds',
                            params: {
                              step: 1,
                              method: 'creditToSales',
                              invoice,
                            },
                          })
                        )
                        : dispatch(openModal({ modal: 'voidInvoice' }));
                      dispatch(
                        sendNewGTMEvent('pos-sale-managed', {
                          id: get(invoice, 'id', ''),
                          action: 'cancel',
                        })
                      );
                    }}
                  >
                    {showVoidIcon(invoice, country, company) && (
                      <Icon icon='note' extraClass='icon-white' />
                    )}
                    <div className='d-none d-sm-inline'>
                      {getVoidTitle(invoice, country)}
                    </div>
                  </button>
                )}
                {!isElectronic && invoiceStatus === 'open' && (
                  <Tooltip
                    visible={!can('edit-open', 'invoices')}
                    overlay={I18n.get(
                      `userNotAllowed.edit.${documentType}`,
                      'Necesitas permiso del administrador para editar el precio.'
                    )}
                    placement='bottom'
                  >
                    <Button
                      type='button'
                      disabled={!canEdit()}
                      onClick={() => {
                        if (!canEdit()) {
                          return;
                        }
                        [
                          onEdit(),
                          dispatch(
                            sendNewGTMEvent('pos-sale-managed', {
                              id: get(invoice, 'id', ''),
                              action: 'edit',
                            })
                          ),
                        ];
                      }}
                      leftIcon='pencil'
                      text={I18n.get('edit', 'editar')}
                    />
                  </Tooltip>
                )}
                {canSendOrPrint() &&
                  (get(company, 'id') === '1507139' ||
                    newShareInvoiceAvailable({ country }) ? (
                    <>
                      <Button
                        type='button'
                        onClick={() => [
                          openNewModal({
                            component: ShareInvoice,
                            name: 'ShareInvoice',
                            title:
                              I18n.get('share', 'Compartir') +
                              ' ' +
                              I18n.get('invoice', 'factura'),
                            size: 'large',
                            props: { invoice },
                          }),
                        ]}
                        leftIcon='share'
                        text={I18n.get('share', 'Compartir')}
                      />
                    </>
                  ) : (
                    <Button
                      type='button'
                      onClick={() => [
                        dispatch(
                          openModal({ modal: 'sendEmail', params: { invoice } })
                        ),
                        dispatch(
                          sendNewGTMEvent('pos-sale-managed', {
                            id: get(invoice, 'id', ''),
                            action: 'email',
                          })
                        ),
                      ]}
                      leftIcon='mail'
                      text={I18n.get('send', 'enviar')}
                    />
                  ))}
              </>
            )}

            {!!invoice.offlineStatus &&
              (invoice.offlineStatus === 'error' ||
                invoice.offlineStatus === 'syncing' ||
                (invoice.offlineStatus === 'pending' &&
                  invoice.statusInProcess === 1)) && (
                <>
                  <Button
                    type='button'
                    disabled={invoice.offlineStatus !== 'error'}
                    onClick={() => {
                      dispatch(setInvoice(invoice));
                      dispatch(openModal({ modal: 'editInvoice' }));
                    }}
                    leftIcon='pencil'
                    text={I18n.get('edit', 'editar')}
                  />
                  <Button
                    type='button'
                    disabled={
                      invoice.offlineStatus !== 'error' &&
                      !(
                        invoice.offlineStatus === 'pending' &&
                        invoice.statusInProcess === 1
                      )
                    }
                    onClick={() => dispatch(syncOffline(false, true))}
                    leftIcon={invoice.offlineStatus ? '' : 'rotate'}
                    loading={invoice.offlineStatus === 'syncing'}
                    text={invoice.offlineStatus ? I18n.get('syncning', 'Sincronizando') : I18n.get('sync', 'sincronizar')}
                  />
                </>
              )}

            {canSendOrPrint() && (
              <Button
                type='button'
                onClick={() => [
                  dispatch(setTypeToPrint('invoice')),
                  dispatch(
                    sendNewGTMEvent('pos-sale-managed', {
                      id: get(invoice, 'id', ''),
                      action: 'print',
                    })
                  ),
                ]}
                leftIcon='printer'
                text={I18n.get('print', 'imprimir')}
              />
            )}

            <button
              type='button'
              className='btn btn-submit d-block d-sm-none'
              onClick={() => onListView()}
            >
              <Icon icon='list' className='icon-white' />
            </button>
          </div>
        </div>
      </div>
      {!!invoice.offlineStatus && invoice.offlineStatus === 'error' && (
        <p className='h4 px-4 text-danger text-capitalize-first'>
          {replaceAndParse(invoice.error)}
        </p>
      )}

      {getLegalStatusKey(invoice) === 'legalStatus.contingency' && (
        <p className='h5 text-capitalize-first bg-warning rounded p-2'>
          {I18n.get('contingencyWarning1', 'Recuerda enviar a la DIAN')}{' '}
          <b>{I18n.get('contingencyWarning2', 'desde Alegra Contabilidad')}</b>{' '}
          {I18n.get(
            'contingencyWarning3',
            'los documentos equivalentes que generaste durante el día sin IVA, conoce cómo hacerlo'
          )}{' '}
          <a
            className='btn-link'
            href='https://ayuda.alegra.com/es/ayuda-contingencia-dia-sin-iva-pos'
            target='_blank'
            rel='noreferrer'
          >
            {I18n.get('here', 'aquí')}
          </a>
        </p>
      )}

      <Confirmation
        isOpen={confirmationOpen}
        onClose={() => dispatch(closeModal({ modal: 'voidInvoice' }))}
        onConfirm={() => checkCountry()}
        title={I18n.get('voidInvoice', 'anular venta')}
        closeText={I18n.get('cancel', 'Cancelar')}
        confirmText={I18n.get('confirmVoid', 'Confirmar anulación')}
        body={
          <>
            <p className='text-center h4 text-capitalize-first p-5'>
              {replaceAndParse(
                I18n.get(
                  'areYouSureToVoidInvoice',
                  'Confirma que deseas anular la venta <strong> {} </strong> para que se elimine de tus registros.'
                ),
                [getNumber(invoice, country)]
              )}
            </p>
            {!!error && (
              <p className='text-center h4 text-capitalize-first text-danger pb-4'>
                {error}
              </p>
            )}
          </>
        }
        submitting={loading}
        disableCancel={loading}
        hideRequired
      />
      <VoidInvoiceCause
        voidInvoice={(cause) => voidInvoice(cause)}
        submitting={loading}
        submitError={error}
      />
    </>
  );
};

DetailHeader.propTypes = {
  invoice: PropTypes.object,
  onListView: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default DetailHeader;
