import React from 'react';
import { Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays'
import { BigNumber } from 'bignumber.js'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useSelector } from 'react-redux'

import { companySelector, country as countrySelector, membershipSelector } from '../../../selectors/company';
import { renderField } from '../../forms/fields/V0/Fields';
import { calculateItemsValues, calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils';
import useDecimalsVersionsGroup from '../../../hooks/useDecimalsVersionsGroup/hook';

import { Icon } from '@alegradev/smile-ui-react';
import { useFormat } from '../../../hooks/useFormat';

const KitInfo = ({ values, form, ...props }) => {
  const history = useHistory();
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const { decimal, fmt } = useFormat();

  const items = get(values, 'items')

  const itemsValues = !!items
    ? calculateItemsValues({items: items.map(item => !!item
      ? ({
        ...item.item,
        quantity: item.quantity,
        price: get(item, 'item.inventory.unitCost'),
        tax: [],
      }) : null).filter(i => !!i), decimal, country, company, membership})
    : null

  const editKitProduct = ({ item }) => {
    history.push('/items/edit/' + item.id)
  }
  return (
    <>
      <div className="p-0 col-12 form-row form-field-array-wrapper">
        <FieldArray name="items">
          {({ fields }) =>
            fields.map((name, index) => {
              const isItemSelected = !!get(items, `${index}.item.name`)
              const itemValue = !!isItemSelected
                ? isDecimalActive
                  ? calculateSingleItemValuesWithDecimals(
                      {
                        ...get(items, `${index}.item`),
                        price: get(items, `${index}.item.inventory.unitCost`),
                        quantity: get(values, `items[${index}].quantity`),
                        tax: [],
                      },
                      decimal
                    )
                  : calculateSingleItemValues(
                      {
                        ...get(items, `${index}.item`),
                        price: get(items, `${index}.item.inventory.unitCost`),
                        quantity: get(values, `items[${index}].quantity`),
                        tax: [],
                      },
                      decimal
                    )
                : null;
              return (
                <div key={index} className="w-100">
                  <div className="combo-item-container d-flex aling-items-center form-row">
                    <div className="col-md-4 col-lg-2 d-none d-md-block">
                      <div className="image-container pointer" onClick={() => editKitProduct(items[index])}>
                        {!!get(items, `${index}.item.images.length`) && (
                          <img
                            src={get(items, `${index}.item.images.${get(items, `${index}.item.images.length`, 1) - 1}.url`)}
                            alt=""
                            className="w-100 h-100 bg-white"
                          />
                        )}

                        <div className="item-view__empty-image w-100 h-100 d-flex align-items-center justify-content-center position-absolute">
                          <Icon icon='tag' extraClass="icon-gray icon x2" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-8 col-lg-7 item-container d-flex flex-column justify-content-center">
                      <p className={`h4 mb-1 ${!isItemSelected ? 'text-subtitle' : ''}`}>
                        {get(items, `${index}.item.name`)}
                      </p>
                      <p className="h5 m-0 text-subtitle">
                        {!!isItemSelected
                          ? !!itemValue ? itemValue.total.toFormat(decimal, fmt) : 0
                          : I18n.get('selectProductHelp', 'Agrega aquí los productos de tu combo')
                        }
                      </p>
                    </div>

                    <div className="col-sm-4 col-lg-3 col-md-12 form-row align-items-end">
                      <Field
                        disabled={true}
                        name={`${name}.quantity`}
                        component={renderField}
                        className="col-10"
                        type="number"
                        label={capitalize(I18n.get('quantity', 'cantidad'))}
                      />
                    </div>
                  </div>

                </div>
              )
            })
          }
        </FieldArray>

        <div className="d-flex justify-content-between align-items-center w-100">
          <span></span>
          <p className="text-capitalize-first h4 text-subtitle">
            {I18n.get('totalCost', 'costo total')}
            {': '}
            {!!itemsValues ? itemsValues.total.toFormat(decimal, fmt) : 0}
          </p>
        </div>
      </div>
    </>
  )
}

export default KitInfo;
