import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  companySelector,
  membershipSelector,
  membershipPlanSelector,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';
import dayjs from 'dayjs';
import { get } from 'lodash';

export const DECIMALS_ACTIVE_COUNTRIES = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.SPAIN,
  COUNTRIES.CHILE,
  COUNTRIES.ARGENTINA,
];

const MEXICO_DECIMALS_ACTIVE_IDS = [
  '836826',
  '1181156',
  '1180239',
  '1346031',
  '1397006',
  '8367',
  '23924',
  '49319',
  '54278',
  '59751',
  '65640',
  '68295',
  '85948',
  '100000',
  '120350',
  '143259',
  '144002',
  '145346',
  '146891',
  '150101',
  '153404',
  '184809',
  '190780',
  '192980',
  '200064',
  '228049',
  '243594',
  '245671',
  '250761',
  '268521',
  '269060',
  '284342',
  '288167',
  '308536',
  '315140',
  '318028',
  '328090',
  '330864',
  '331032',
  '333306',
  '341038',
  '363925',
  '386332',
  '404802',
  '406231',
  '426815',
  '470420',
  '512518',
  '522094',
  '538560',
  '557522',
  '572115',
  '604849',
  '624997',
  '629944',
  '638634',
  '647882',
  '678133',
  '680571',
  '681490',
  '682976',
  '683031',
  '684778',
  '685117',
  '701977',
  '731789',
  '732014',
  '746281',
  '760790',
  '784657',
  '793390',
  '803458',
  '813008',
  '820912',
  '826923',
  '827134',
  '835843',
  '846468',
  '846498',
  '846516',
  '846529',
  '846690',
  '961892',
  '1005549',
  '1014910',
  '1040168',
  '1117982',
  '1132133',
  '1132265',
  '1157806',
  '1167090',
  '1176383',
  '1176418',
  '1190261',
  '1190749',
  '1191326',
  '1196146',
  '1198535',
  '1210736',
  '1217794',
  '1218499',
  '1218637',
  '1231330',
  '1291684',
  '1307701',
  '1309819',
  '1322283',
  '1324413',
  '1326568',
  '1332996',
  '1333767',
  '1335092',
  '1350862',
  '1351611',
  '1358168',
  '1359893',
  '1360933',
  '1387531',
  '1398014',
  '1400815',
  '1400816',
  '1401104',
  '776342',
  '562122',
  '1120339',
  '1181156',
  '1180239',
  '354782',
  '811653',
  '30239',
  '1113382',
  '1191621',
  '1220144',
  '1263992',
  '1371683',
  '1395215',
  '605419',
  '1166566',
  '1024690',
  '1143007',
  '416593',
  '1397006',
  '1397729',
];

const COLOMBIA_DECIMALS_ACTIVE_IDS = [
  '224386',
  '868768',
  '1233763',
  '519598',
  '1286884',
  '1218006',
  '394725',
  '268664',
  '293904',
  '89273',
  '1272521',
  '1344979',
  '1326432',
  '921591',
  '295148',
  '528234',
  '435970',
  '406725',
  '819555',
  '422030',
  '775327',
  '427377',
  '279643',
  '444629',
  '1344979',
  '357259',
  '295148',
  '408310',
  '1326432',
  '382520',
  '365463',
  '1176899',
  '700285',
  '1355496',
  '733751',
  '1157050',
  '538491',
  '257565',
  '921591',
  '775327',
  '1240227',
  '404507',
  '380571',
  '929766',
  '498978',
  '431961',
  '336003',
  '17883',
  '723632',
  '932579',
  '642327',
  '234904',
  '528234',
  '114190',
  '1401330',
  '633905',
  '1107863',
  '622865',
  '186602',
  '460261',
  '617082',
  '303572',
  '915834',
  '499995',
  '113779',
  '1160390',
  '417850',
  '1215115',
  '588132',
  '301847',
  '1264283',
  '926281',
  '1399639',
  '127667',
  '1334435',
  '37667',
  '418137',
  '176018',
  '295065',
  '156413',
  '801212',
  '627092',
  '888320',
  '401164',
  '418738',
  '406668',
  '1422013',
  '1284395',
  '301815',
  '1057652',
  '476484',
  '761592',
  '1195992',
  '1395017',
  '314434',
  '743651',
  '627814',
  '385882',
  '758727',
  '358603',
  '797654',
  '429766',
  '888320',
  '301847',
  '311184',
  '386659',
  '1460976',
  '1433587',
  '1259525',
  '1447673',
  '1349729',
  '2183',
  '55012',
  '1414639',
  '884012',
  '493347',
  '1212056',
  '1346398',
  '1441595',
  '753821',
  '118681',
  '492005',
  '1176899',
  '982000',
  '372181',
  '1422229',
  '542745',
  '1161251',
  '352113',
  '252105',
  '593434',
  '759850',
  '210667',
  '427927',
  '128404',
  '414884',
  '471020',
  '1223987',
  '854027',
  '1439431',
  '578589',
  '156185',
  '170358',
  '67998',
  '579854',
  '413314',
  '1404049',
  '1422229',
  '477057',
  '1243448',
  '1300487',
  '1427317',
  '1365437',
  '561594',
  '990205',
  '1387451',
  '217528',
  '1196063',
  '990205',
  '955803',
  '1448188',
  '1483204',
  '1280761',
  '360817',
  '1436942',
  '1451883',
  '460894',
  '1015466',
  '476390',
  '424870',
  '771419',
  '4742',
  '396594',
  '1486505',
  '62961',
  '163105',
  '249496',
  '291111',
  '295455',
  '289070',
  '271524',
  '306310',
  '322616',
  '324743',
  '326936',
  '371870',
  '314058',
  '381331',
  '382484',
  '394365',
  '394876',
  '395658',
  '396345',
  '399019',
  '364863',
  '383927',
  '400479',
  '400814',
  '402147',
  '403137',
  '406211',
  '406288',
  '408747',
  '409835',
  '410306',
  '411821',
  '414018',
  '414465',
  '414470',
  '414856',
  '393840',
  '403208',
  '418120',
  '418765',
  '419083',
  '419098',
  '419333',
  '420150',
  '420953',
  '421938',
  '423311',
  '423592',
  '423986',
  '424247',
  '425096',
  '426923',
  '427979',
  '432293',
  '433671',
  '408542',
  '412612',
  '423081',
  '424758',
  '444597',
  '448756',
  '452724',
  '459110',
  '410336',
  '417947',
  '395533',
  '441592',
  '476456',
  '481371',
  '503044',
  '507386',
  '517747',
  '529449',
  '532050',
  '428623',
  '537282',
  '545172',
  '566857',
  '581851',
  '485709',
  '501889',
  '591962',
  '602650',
  '613866',
  '575167',
  '664168',
  '55012',
  '1388262',
  '547919',
  '454655',
  '1242411',
  '1472878',
  '436778',
  '203889',
  '1315247',
  '39728',
  '656635',
  '483816',
  '362404',
  '1417820',
  '666149',
  '1451730',
  '1408342',
  '1285167',
  '140126',
  '409634',
  '1353056',
  '2183',
  '4921',
  '7108',
  '20028',
  '20412',
  '11333',
  '23131',
  '24686',
  '30427',
  '39728',
  '50974',
  '51029',
  '51379',
  '32309',
  '35893',
  '21459',
  '22072',
  '46045',
  '40784',
  '54638',
  '55012',
  '62758',
  '62759',
  '63734',
  '66695',
  '80211',
  '104743',
  '107120',
  '109914',
  '110929',
  '113779',
  '121494',
  '127667',
  '130106',
  '65717',
  '71817',
  '95124',
  '161536',
  '163105',
  '170628',
  '171369',
  '175253',
  '176001',
  '178627',
  '179941',
  '180690',
  '184753',
  '185817',
  '193513',
  '197578',
  '208699',
  '215679',
  '216700',
  '225848',
  '226255',
  '227284',
  '176018',
  '198106',
  '181196',
  '193504',
  '214735',
  '228123',
  '230134',
  '239164',
  '246054',
  '251190',
  '257073',
  '258264',
  '258776',
  '260700',
  '269290',
  '271524',
  '273791',
  '274310',
  '274702',
  '276460',
  '278161',
  '278410',
  '279631',
  '197125',
  '209217',
  '284807',
  '285887',
  '289817',
  '290545',
  '291111',
  '292385',
  '295196',
  '252469',
  '273730',
  '254112',
  '258529',
  '286011',
  '246951',
  '297065',
  '301380',
  '301847',
  '303409',
  '304114',
  '304775',
  '304907',
  '305382',
  '305449',
  '306216',
  '306275',
  '306293',
  '306310',
  '307371',
  '308445',
  '308553',
  '309325',
  '309336',
  '309342',
  '309900',
  '310654',
  '311401',
  '311928',
  '312561',
  '312568',
  '312570',
  '313807',
  '314058',
  '317068',
  '317084',
  '317361',
  '317366',
  '317742',
  '317783',
  '319405',
  '296060',
  '323496',
  '324560',
  '325788',
  '326936',
  '326997',
  '329220',
  '329375',
  '330238',
  '330816',
  '331127',
  '331298',
  '335357',
  '335545',
  '335649',
  '335976',
  '271802',
  '338406',
  '338788',
  '338944',
  '298759',
  '300547',
  '340538',
  '344523',
  '345398',
  '346935',
  '348401',
  '350979',
  '352072',
  '311770',
  '353633',
  '359913',
  '360505',
  '361367',
  '362335',
  '362705',
  '363350',
  '365206',
  '365633',
  '367367',
  '372191',
  '372650',
  '372978',
  '372982',
  '374404',
  '374824',
  '376914',
  '326127',
  '328474',
  '332124',
  '340117',
  '348906',
  '356024',
  '380285',
  '382414',
  '382420',
  '384234',
  '384509',
  '386811',
  '388102',
  '391616',
  '393983',
  '394365',
  '394834',
  '397543',
  '397896',
  '398022',
  '364863',
  '377480',
  '401820',
  '403137',
  '403208',
  '403653',
  '404002',
  '405482',
  '407322',
  '408270',
  '408762',
  '408949',
  '410157',
  '410166',
  '411723',
  '412235',
  '412386',
  '412392',
  '414470',
  '415507',
  '416038',
  '420536',
  '420607',
  '423892',
  '423919',
  '426118',
  '427273',
  '427290',
  '428208',
  '428937',
  '433454',
  '435876',
  '436087',
  '436582',
  '439073',
  '439180',
  '439573',
  '441486',
  '442720',
  '412363',
  '416076',
  '431544',
  '447165',
  '452151',
  '460382',
  '462735',
  '476003',
  '434303',
  '444848',
  '476456',
  '477278',
  '479395',
  '481146',
  '481371',
  '427841',
  '432247',
  '436450',
  '441084',
  '486313',
  '490353',
  '491507',
  '425179',
  '508863',
  '511410',
  '513574',
  '516486',
  '522576',
  '525032',
  '531157',
  '462749',
  '449521',
  '535785',
  '537831',
  '542832',
  '543503',
  '560094',
  '561364',
  '561475',
  '562130',
  '566857',
  '570249',
  '571442',
  '581170',
  '581637',
  '584081',
  '591010',
  '455211',
  '462645',
  '591962',
  '594913',
  '595692',
  '599954',
  '605260',
  '605495',
  '606066',
  '609139',
  '611141',
  '612431',
  '612596',
  '613363',
  '620566',
  '622537',
  '624801',
  '629010',
  '630439',
  '630675',
  '633879',
  '635831',
  '529887',
  '571899',
  '644230',
  '644464',
  '645368',
  '646586',
  '647790',
  '656517',
  '657152',
  '659592',
  '662220',
  '1370186',
  '285611',
  '1441516',
  '325243',
  '1456436',
  '722056',
  '1491326',
  '20389',
  '25846',
  '30737',
  '30800',
  '32972',
  '33159',
  '33578',
  '33654',
  '33697',
  '34167',
  '34200',
  '34205',
  '34215',
  '34239',
  '34292',
  '34334',
  '34339',
  '34374',
  '34375',
  '34380',
  '34410',
  '34477',
  '35280',
  '35378',
  '35666',
  '35690',
  '35697',
  '35762',
  '29996',
  '33481',
  '34168',
  '34181',
  '34209',
  '34301',
  '34333',
  '34378',
  '34703',
  '538519',
  '415751',
  '438581',
  '415751',
  '427025',
  '495766',
  '499693',
  '509937',
  '536086',
  '547933',
  '549599',
  '839',
  '9861',
  '16726',
  '39728',
  '45496',
  '47113',
  '27521',
  '55351',
  '56844',
  '74340',
  '134299',
  '134326',
  '58311',
  '151710',
  '178651',
  '188715',
  '146003',
  '212345',
  '214735',
  '217534',
  '219516',
  '223726',
  '159589',
  '172038',
  '181586',
  '181699',
  '222258',
  '222290',
  '238221',
  '240280',
  '246409',
  '249111',
  '272820',
  '209984',
  '286630',
  '292478',
  '262055',
  '282216',
  '261781',
  '272822',
  '297289',
  '298753',
  '299650',
  '292085',
  '309300',
  '314058',
  '324804',
  '325936',
  '327544',
  '332124',
  '286220',
  '300286',
  '351124',
  '352392',
  '299362',
  '305162',
  '318336',
  '353633',
  '357699',
  '366084',
  '367662',
  '367995',
  '370871',
  '372192',
  '374213',
  '374765',
  '375147',
  '375236',
  '375399',
  '376773',
  '376840',
  '377737',
  '332731',
  '378825',
  '379048',
  '381613',
  '382425',
  '382484',
  '384114',
  '384343',
  '384570',
  '384654',
  '385516',
  '385577',
  '386301',
  '386702',
  '387109',
  '388399',
  '389537',
  '390243',
  '392728',
  '394019',
  '394810',
  '394827',
  '394842',
  '395461',
  '396862',
  '397574',
  '397589',
  '398022',
  '360719',
  '364851',
  '367367',
  '371201',
  '376568',
  '382749',
  '399453',
  '399600',
  '399655',
  '400291',
  '400817',
  '400876',
  '401164',
  '401410',
  '401415',
  '402451',
  '403208',
  '403438',
  '403594',
  '404038',
  '404156',
  '404587',
  '404881',
  '405301',
  '405778',
  '405818',
  '406668',
  '406740',
  '407313',
  '408088',
  '408506',
  '408553',
  '408600',
  '408664',
  '408747',
  '408815',
  '409332',
  '409382',
  '409423',
  '409835',
  '410583',
  '410981',
  '411157',
  '411310',
  '411668',
  '411708',
  '412269',
  '412295',
  '412757',
  '413093',
  '413296',
  '413299',
  '413321',
  '414126',
  '414405',
  '414465',
  '414470',
  '414494',
  '414568',
  '414665',
  '414780',
  '414833',
  '415144',
  '415266',
  '415807',
  '415814',
  '416326',
  '417337',
  '301985',
  '308968',
  '352452',
  '375235',
  '386425',
  '388217',
  '404205',
  '417715',
  '417947',
  '417967',
  '418090',
  '418129',
  '418544',
  '418712',
  '419083',
  '419316',
  '420768',
  '421084',
  '421481',
  '421711',
  '421741',
  '422079',
  '423294',
  '423407',
  '423697',
  '423919',
  '424078',
  '424247',
  '424342',
  '424347',
  '424560',
  '424737',
  '424789',
  '425927',
  '426179',
  '427231',
  '427560',
  '428117',
  '428202',
  '428875',
  '429159',
  '429505',
  '429783',
  '429846',
  '430051',
  '430337',
  '430616',
  '432085',
  '432230',
  '432379',
  '432421',
  '432454',
  '433416',
  '433460',
  '433666',
  '433757',
  '433820',
  '433915',
  '434114',
  '436359',
  '436450',
  '437474',
  '438601',
  '440450',
  '440495',
  '442236',
  '443333',
  '443494',
  '359467',
  '393840',
  '404131',
  '406840',
  '406848',
  '407667',
  '409325',
  '409593',
  '414400',
  '415273',
  '417683',
  '418443',
  '418765',
  '419333',
  '421550',
  '421600',
  '422455',
  '423429',
  '424097',
  '424758',
  '424810',
  '426882',
  '427894',
  '429145',
  '429183',
  '429424',
  '430761',
  '445304',
  '447713',
  '450495',
  '450724',
  '453394',
  '454095',
  '454277',
  '454493',
  '458068',
  '458574',
  '459110',
  '460870',
  '462677',
  '465479',
  '465688',
  '465709',
  '472449',
  '474102',
  '408643',
  '417775',
  '417815',
  '420754',
  '399081',
  '404068',
  '404142',
  '408524',
  '408532',
  '408554',
  '437329',
  '444292',
  '448823',
  '451109',
  '480661',
  '481126',
  '482473',
  '421892',
  '421938',
  '422483',
  '423311',
  '429466',
  '433870',
  '487585',
  '410306',
  '491725',
  '492793',
  '422087',
  '423584',
  '495445',
  '496832',
  '498494',
  '499875',
  '500812',
  '502386',
  '507386',
  '507882',
  '508060',
  '509057',
  '510725',
  '515705',
  '516475',
  '517104',
  '518849',
  '521242',
  '524956',
  '526565',
  '526611',
  '527823',
  '528267',
  '529232',
  '529712',
  '455480',
  '458256',
  '472029',
  '479199',
  '445292',
  '460445',
  '434160',
  '536099',
  '536722',
  '539075',
  '539155',
  '543904',
  '544522',
  '551295',
  '554189',
  '555213',
  '557417',
  '557927',
  '562130',
  '564752',
  '566333',
  '570634',
  '570898',
  '572170',
  '575167',
  '576774',
  '581699',
  '582104',
  '582328',
  '582430',
  '583638',
  '583900',
  '584118',
  '584606',
  '590676',
  '510737',
  '471955',
  '490224',
  '490876',
  '496798',
  '452590',
  '454449',
  '457194',
  '472123',
  '486804',
  '494839',
  '591368',
  '592065',
  '593292',
  '598389',
  '598513',
  '598701',
  '599919',
  '601283',
  '601638',
  '527516',
  '534857',
  '602805',
  '543024',
  '604366',
  '605211',
  '544284',
  '606294',
  '606755',
  '606792',
  '607535',
  '608446',
  '609783',
  '610328',
  '549491',
  '614003',
  '617220',
  '561364',
  '620566',
  '625173',
  '630636',
  '637845',
  '529938',
  '502117',
  '536473',
  '576440',
  '578789',
  '579003',
  '580321',
  '582039',
  '582610',
  '583964',
  '584545',
  '584940',
  '639058',
  '639359',
  '645408',
  '646905',
  '648173',
  '652038',
  '598123',
  '599257',
  '659985',
  '662392',
  '669741',
  '543462',
  '1325568',
  '1404049',
  '131987',
  '386756',
  '1268011',
  '1528621',
  '1447965',
  '522211',
  '1141207',
  '1070075',
  '1389187',
  '1410588',
  '1415764',
  '1157845',
  '925604',
  '589608',
  '1319059',
  '1536842',
  '1060195',
  '925604',
  '589608',
  '1319059',
  '1536842',
  '1060195',
  '293904',
  '1536170',
  '908105',
  '1352102',
  '1084920',
  '375059',
  '1486448',
  '1433472',
  '1321830',
  '1184011',
  '1169182',
  '849942',
  '1060195',
  '1535179',
  '1084561',
  '416323',
  '1281518',
  '1499027',
  '921461',
  '303591',
  '1529537',
  '1397921',
  '634867',
  '728594',
  '649641',
  '1523786',
  '490034',
  '885006',
  '888069',
  '1529772',
  '1507330',
  '1456603',
  '704370',
  '476070',
  '1407450',
  '401427',
  '594608',
  '659271',
  '1316002',
  '38884',
  '1278029',
  '1405791',
  '1457420',
  '1471774',
  '385793',
  '1534134',
  '1205058',
  '1268274',
  '1359969',
];

export const DECIMALS_ACTIVE_IDS = [
  ...MEXICO_DECIMALS_ACTIVE_IDS,
  ...COLOMBIA_DECIMALS_ACTIVE_IDS,
];

export const calculateAvailabilityByRegistryDate = ({ company, country }) => {
  const registryDate = get(company, 'registryDate', '');

  if (!registryDate) return false;

  const parsedDate = dayjs(registryDate);

  if (country === COUNTRIES.COLOMBIA && parsedDate.isAfter('2024-09-10')) {
    return true;
  }

  if (country === COUNTRIES.MEXICO && parsedDate.isBefore('2019-12-31')) {
    return true;
  }

  return false;
};

const planAvailabilityRules = { [COUNTRIES.COLOMBIA]: ['plus'] };

export const calculateAvailabilityByPlanKeyword = ({ membership, country }) => {
  const planName =
    get(membership, 'plan.keyword', null) ||
    get(membership, 'planKeyword', null);

  const rules = planAvailabilityRules[country];

  return rules ? rules.includes(planName) : false;
};

const useDecimalsVersionsGroup = () => {
  const [isDecimalActive, setIsDecimalActive] = useState(false);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);
  const membershipPlan = useSelector(membershipPlanSelector);
  const country = useSelector(countrySelector);

  const dateValidation = useMemo(() => {
    return calculateAvailabilityByRegistryDate({ company, country });
  }, [company, country]);

  const planValidation = useMemo(() => {
    return calculateAvailabilityByPlanKeyword({
      membership: Object.keys(membership) > 0 ? membership : membershipPlan,
      country,
    });
  }, [membership, membershipPlan, country]);

  useEffect(() => {
    if (
      DECIMALS_ACTIVE_COUNTRIES.includes(country) ||
      DECIMALS_ACTIVE_IDS.includes(company?.id) ||
      dateValidation ||
      planValidation
    ) {
      setIsDecimalActive(true);
    }
  }, [company, country]);

  return {
    isDecimalActive,
  };
};

export default useDecimalsVersionsGroup;
