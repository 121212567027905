// src/components/inventoryAdjustment/InventoryAdjustment.jsx
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import { I18n } from "@aws-amplify/core";
import { get, isEmpty } from "lodash";

import MinimalTable from "../../common/MinimalTable";
import InventoryAdjustmentModal from "../../modals/inventoryAdjustment/InventoryAdjustmentModal";

import { renderField, renderSelect } from "../fields/V0/Fields";
import { Icon } from '@alegradev/smile-ui-react';
import { activeInventoryAdjustmentsNumerations } from "../../../selectors/inventoryAdjustmentsNumerations";

import { useFormat } from "../../../hooks/useFormat";
import useFetchItems from "../../inventory/InventoryAdjustments/hooks/useFetchItems";
import useColumns from "./columns";

const InventoryAdjustment = ({ form, values }) => {
  const { fmt, decimal } = useFormat();
  const numerations = useSelector(activeInventoryAdjustmentsNumerations);
  const defaultNumeration = numerations.find((numeration) => get(numeration, 'isDefault') === true)

  const formItems = useMemo(() => {
    return get(form.getFieldState('items'), 'value', []).map((row) => +get(row, 'item.id'));
  }, [form]);

  const { items, loading, error, search } = useFetchItems(formItems);
  const [inputSearchValue, setInputSearchValue] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowName, setSelectedRowName] = useState("");

  const handleInputChange = useCallback((value) => {
    setInputSearchValue(value);
  }, []);

  useEffect(() => {
    search(inputSearchValue);
    return () => {
      search.cancel();
    };
  }, [inputSearchValue, search]);

  const addItem = () => {
    form.mutators.push("items");
  };

  const columns = useColumns({
    decimal,
    fmt,
    form,
    items,
    loading,
    error,
    handleInputChange,
    setOpenModal,
    setSelectedRowName
  });

  return (
    <div className="form-body h-100 w-100 overflow-hidden pl-5 pb-5 pr-5 pt-0">
      <div className="form-body__fields form-row justify-content-between px-3">
        <Field
          name="numeration"
          className="col-md-4"
          label={I18n.get("numeration", "Numeracion")}
          component={renderSelect}
          options={numerations}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isLoading={isEmpty(numerations)}
          defaultValue={defaultNumeration}
          isSearchable={true}
          menuPosition="absolute"
          required
        />
        <Field
          name="observations"
          className="col-md-7"
          label={I18n.get("observations", "Observaciones")}
          component={renderField}
          type="text"
        />
      </div>
      <MinimalTable
        className="px-3 mt-5"
        title={I18n.get("itemsToAdjust", "Productos a ajustar")}
        columns={columns}
        data={values}
        formTable
      />
      <button
        className="btn btn-secondary primary"
        type="button"
        style={{ border: "none", outline: "none", boxShadow: "none" }}
        onClick={() => addItem()}
      >
        <Icon icon='plus' extraClass="icon icon-primary mr-2" />
        {I18n.get("addAnotherItem", "Agregar otro producto")}
      </button>

      <InventoryAdjustmentModal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        onConfirm={() => {
          form.change(`${selectedRowName}.allowCostChange`, true)
          setOpenModal(false)
        }}
      />
    </div>
  )
}

export default InventoryAdjustment;
